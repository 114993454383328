<template>
  <div class="title-heading">
    <h1>Історія замовлень</h1>
  </div>
  <div class="personal-order-list-table">
    <div class="personal-order-list-top">
      <div class="name name-date_status">Дата/статус:</div>
      <div class="name name-price">Сума замовлення:</div>
      <div class="name name-product">Товар:</div>
    </div>
    <!--personal area order list item-->
    <HistoryItemRow v-for="(order, index) in list" :key="index" :order="order"/>
    <!--end personal area order list item-->
  </div>
</template>
<script>
import HistoryItemRow from '@/components/HistoryItemRow';

export default {
  name      : 'CabinetHistory',
  components: {HistoryItemRow},
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getHistory()
  },
  computed: {
    payment() {
      return this.$store.getters.getPayment
    },
    delivery() {
      return this.$store.getters.getDelivery
    }
  },
  methods : {
    getHistory() {
      let ok        = false
      let userInfo  = localStorage.getItem('user_info')
      let headers   = {};
      let initCache = localStorage.getItem('initData');
      if (initCache && initCache.length) {
        headers.initData = initCache;
        ok               = true
      }
      let body = {}
      if (userInfo) {
        body.user = JSON.parse(userInfo)
        ok        = true
      }
      if (!ok) {
        console.log('no auth data')
        return
      }
      return fetch(
          '/public/history',
          {
            method : 'POST',
            body   : JSON.stringify(body),
            headers: headers
          }
      ).then(result => result.json()).then(json => {
        if (json && json.result) {
          this.list = json.result
        }
        if (json?.error) {
          window.localStorage.removeItem('user_info')
          this.$router.push({'name': 'login'})
        }
      })
    }
  }
}
</script>
