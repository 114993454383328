<template>

  <div class="modal custom-modal fade" id="show_delivery" tabindex="-1" role="dialog" aria-hidden="true" @click="onClosed">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" @click.stop="">
        <div class="modal-header">
          <h2>Доставка</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClosed">
            <img src="/images/icons/close.svg" alt="">
          </button>
        </div>
        <div class="modal-body">
          <div class="map-wrapper" v-if="point">
            <Map :noButton="true" :point="point"></Map>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a href="#show_delivery" ref="openLink" data-toggle="modal" class="default-link"
     style="position: fixed;top:0;left:0;height: 0;width: 0; overflow: hidden"></a>
</template>

<script>
import Map from '@/components/Map';

export default {
  name      : 'ModalDeliveryMap',
  components: {Map},
  data() {
    return {
      point: undefined
    }
  },
  mounted() {
    window.showDeliveryMapModal = this.showPoint
    // let myModalEl = document.getElementById('show_delivery')
    // myModalEl.addEventListener('hide.bs.modal', function (event) {
    //   console.log('modal closed map')
    //   this.point = undefined
    // })
  },
  methods: {
    onClosed() {
      document.getElementById('show_delivery').click()
      setTimeout(() => {
        this.point = undefined
      }, 100)
    },
    showPoint(point) {
      // console.log('show point', point)
      this.$refs.openLink.click()
      if (!point.zoom) {
        point.zoom = 14
      }
      this.point = point
    }
  }
}
</script>