<template>
  <div class="main-wrapper wrapper">
    <div class="swiper main-gallery" v-if="home?.settings?.image && home.settings.image.length">
      <HomeSlides :slides="home.settings.image"/>
    </div>

    <h1 v-if="categories.length > 1">{{ $static('home.ti', 'Оберіть ресторан') }}</h1>
    <div class="large-category-list" v-if="categories.length > 1">
      <ul>
        <li v-for="(category, index) in categories" :key="index">
          <router-link :to="'/catalog/'+category.info.url" :style="{'background-image': $filters.backgroundImage(category.image)}" class="image bg-cover">
            <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="mainstream-products" v-else-if="items">
      <ul class="catalog-grid" @mousedown="e => mouseDownHandlerTarget(e,'catalog-grid')">
        <CatalogItem v-for="(item,index) in items" :key="index" :item="item" :category-object="categories[0]"/>

        <li class="catalog-item more-catalog-item" @click="getMore" v-if="more">
          <div class="item-holder d-flex align-items-center text-center justify-content-center h-100">
            <div class="more-content">
              <div class="text-wrapper">
                {{ $static('catalog.more_load', 'Завантажити ще') }}
              </div>
              <div class="icons-holder">
                <img src="/images/icons/load.svg">
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="custom-home-text" v-if="home && home.template" v-html="home.template"></div>

  </div>

</template>

<script>
import CatalogItem from '@/views/CatalogItem';
import HomeSlides from '@/views/HomeSlides';
import axios from 'axios'

export default {
  name: 'Home',
  data() {
    return {
      items  : [],
      page   : 1,
      perPage: 11,
      more   : true,

      optionSelect: [],
      swiperReview: undefined,
      swiperMain  : undefined,
      home        : undefined
    }
  },
  computed  : {
    settings() {
      return this.$store.getters.getSettings
    },
    // landing() {
    //   return this.$store.getters.landing
    // },
    lang() {
      return this.$lang()
    },
    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    },
    title() {
      return this.settings && this.settings.title ? this.settings.title : undefined
    },
    subtitle() {
      return this.settings && this.settings.subtitle ? this.settings.subtitle : undefined
    },

    tags() {
      return this.$store.getters.getTags
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    }
  },
  methods   : {

    getMore() {
      this.page += 1;
      this.getItems()
    },
    fancyClick(e) {
      if (window.change) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    mouseDownHandlerTarget(e, targetClass) {
      let target = e.target;
      while (!target.className.includes(targetClass)) {
        target = target.parentElement
      }
      window.ele = target;
      window.mouseDownHandler(e)
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    getDiscount(item) {
      return item.preview ? (1 - Math.max(item.price / item.price_old, 0)) * 100 | 0 : ''
    },
    isHot(item) {
      return item.advanced && item.advanced.hot
    },
    getItems(clear) {
      if (this.load) {
        return
      }
      this.load  = true;
      let filter = {priority: true, perPage: this.perPage, page: this.page};
      if (this.categories.length < 2) {
        filter.priority = undefined
      }
      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).map(x => x ? x.id : x);
      }
      axios.post('/public/item/filter', {filter: filter}).then(response => response.data).then(data => {
        this.load = false;
        if (clear) {
          this.items = data.result
        } else {
          this.items.push(...data.result);
        }
        this.page  = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
      }).catch(() => {
        this.load = false;
      })
    },
    categoryObjectID(item) {
      // return {id: 1}
      // console.log(this.$store.getters.getCategories, item.category);
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      // if (!result) {
      //
      // }
      return result
    },
    categoryURL(item) {
      // return {id: 1}
      // console.log(this.$store.getters.getCategories, item.category);
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    }
    // categoryObject() {
    //   return this.$store.getters.getCategories.find(i => i.id === this.categoryId)
    // },
    // categoryName() {
    //   return this.categoryObject ? this.categoryObject.name : ''
    // }
  },
  components: {CatalogItem, HomeSlides},
  mounted() {
    fetch('/public/template/home').then(response => response.json()).then(data => {
      this.home = data
    }).then(() => {
      if (this.home && this.home.settings) {
        let title                                 = this.home.settings.meta_title ? this.home.settings.meta_title : window.location.hostname;
        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = this.home.settings.meta_description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');

        let image = this.home.settings.image ? this.home.settings.image : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);

      }
    });
    this.getItems(true);
  },
  watch: {
    optionSelect() {
      this.getItems(true)
    }
  }
}
</script>
