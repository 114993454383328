<template>
  <label class="label-custom" id="scrollOnEdit">
    {{ $static('order.address', 'Адреса') }}<span>*</span>
  </label>
  <div class="form-row row">
    <div class="form-group" style="width: 100%">
      <label class="form-control custom-input placeholder" @click="openAddress">
        {{ myPlaceholder }}
      </label>
    </div>
  </div>
  <label class="label-custom">
    {{ $static('order.addressor', 'Або вкажіть на карті точку') }} <span>(як в таксі)</span>
  </label>
  <div class="map-holder">
    <div id="map" :style="{'min-height': mapHeight}">
      <div class="center-pin" :class="{'active': dragged}" v-if="modelValue || dragged" @click.stop="">
        <img src="/images/icons/pin-normal.svg" class="pin-normal">
        <img src="/images/icons/pin-active.svg" class="pin-active">
      </div>

    </div>
    <div class="map-buttons" v-if="open">
      <button class="default-btn square-btn" @click="cancel">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 5L5 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5 5L15 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button class="default-btn square-btn" @click="closeMap">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.0253 6.64139C15.2693 6.88546 15.2693 7.28119 15.0253 7.52527L9.19193 13.3586C8.94785 13.6027 8.55213 13.6027 8.30805 13.3586L4.97471 10.0253C4.73064 9.78119 4.73064 9.38546 4.97471 9.14139C5.21879 8.89731 5.61452 8.89731 5.8586 9.14139L8.74999 12.0328L14.1414 6.64139C14.3855 6.39731 14.7812 6.39731 15.0253 6.64139Z"
                fill="black"/>
        </svg>
      </button>
    </div>
    <div class="map-buttons" v-else-if="modelValue">
      <button class="default-btn square-btn" @click="openMap">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M17.879 4.51313C18.0392 4.75572 18.0125 5.0854 17.799 5.29896L10.1386 12.9593C10.0601 13.0378 9.96208 13.094 9.85464 13.1221L6.66429 13.9554C6.56023 13.9826 6.45297 13.982 6.3522 13.9564C6.24506 13.9291 6.14525 13.8735 6.0644 13.7926C5.90751 13.6357 5.84556 13.4074 5.90163 13.1927L6.73496 10.0024C6.75952 9.90836 6.8056 9.81551 6.86934 9.74268L14.558 2.05806C14.6254 1.99076 14.7064 1.94038 14.7947 1.90969C14.8601 1.88694 14.9294 1.875 15 1.875C15.1657 1.875 15.3247 1.94085 15.4419 2.05806L17.799 4.41508C17.8295 4.44559 17.8562 4.47847 17.879 4.51313ZM16.4731 4.85702L15 3.38388L7.90152 10.4824L7.3807 12.4763L9.37467 11.9555L16.4731 4.85702Z"
                fill="black"/>
          <path
              d="M16.3678 14.3003C16.5956 12.3522 16.6681 10.3906 16.5853 8.43399C16.5813 8.34031 16.6165 8.24915 16.6828 8.18285L17.5023 7.36341C17.603 7.26266 17.7752 7.32662 17.7846 7.46878C17.9387 9.79349 17.8803 12.1288 17.6093 14.4455C17.4122 16.1309 16.0586 17.4518 14.3819 17.6391C11.4931 17.962 8.50694 17.962 5.61813 17.6391C3.94142 17.4518 2.58781 16.1309 2.39068 14.4455C2.04523 11.4919 2.04523 8.50811 2.39068 5.55452C2.58781 3.86908 3.94142 2.54824 5.61813 2.36084C7.80959 2.11592 10.057 2.05679 12.2755 2.18347C12.4185 2.19163 12.4837 2.36465 12.3824 2.46591L11.5553 3.29304C11.4897 3.35862 11.3998 3.39384 11.3071 3.39071C9.45175 3.32814 7.58378 3.39894 5.75697 3.60311C4.64855 3.72699 3.76064 4.60167 3.63221 5.69973C3.29805 8.55684 3.29805 11.4432 3.63221 14.3003C3.76064 15.3983 4.64855 16.273 5.75697 16.3969C8.55351 16.7094 11.4465 16.7094 14.243 16.3969C15.3515 16.273 16.2394 15.3983 16.3678 14.3003Z"
              fill="black"/>
        </svg>
      </button>
    </div>


    <div class="tutorial" v-if="!open && !modelValue">
      <div>
        <p>Вкажіть точку доставки</p>
        <button class="default-btn quick_cart_trigger" @click="openMap" style="margin:auto">
          <span class="btn-text">Вказати</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M17.879 4.51313C18.0392 4.75572 18.0125 5.0854 17.799 5.29896L10.1386 12.9593C10.0601 13.0378 9.96208 13.094 9.85464 13.1221L6.66429 13.9554C6.56023 13.9826 6.45297 13.982 6.3522 13.9564C6.24506 13.9291 6.14525 13.8735 6.0644 13.7926C5.90751 13.6357 5.84556 13.4074 5.90163 13.1927L6.73496 10.0024C6.75952 9.90836 6.8056 9.81551 6.86934 9.74268L14.558 2.05806C14.6254 1.99076 14.7064 1.94038 14.7947 1.90969C14.8601 1.88694 14.9294 1.875 15 1.875C15.1657 1.875 15.3247 1.94085 15.4419 2.05806L17.799 4.41508C17.8295 4.44559 17.8562 4.47847 17.879 4.51313ZM16.4731 4.85702L15 3.38388L7.90152 10.4824L7.3807 12.4763L9.37467 11.9555L16.4731 4.85702Z"
                  fill="black"/>
            <path
                d="M16.3678 14.3003C16.5956 12.3522 16.6681 10.3906 16.5853 8.43399C16.5813 8.34031 16.6165 8.24915 16.6828 8.18285L17.5023 7.36341C17.603 7.26266 17.7752 7.32662 17.7846 7.46878C17.9387 9.79349 17.8803 12.1288 17.6093 14.4455C17.4122 16.1309 16.0586 17.4518 14.3819 17.6391C11.4931 17.962 8.50694 17.962 5.61813 17.6391C3.94142 17.4518 2.58781 16.1309 2.39068 14.4455C2.04523 11.4919 2.04523 8.50811 2.39068 5.55452C2.58781 3.86908 3.94142 2.54824 5.61813 2.36084C7.80959 2.11592 10.057 2.05679 12.2755 2.18347C12.4185 2.19163 12.4837 2.36465 12.3824 2.46591L11.5553 3.29304C11.4897 3.35862 11.3998 3.39384 11.3071 3.39071C9.45175 3.32814 7.58378 3.39894 5.75697 3.60311C4.64855 3.72699 3.76064 4.60167 3.63221 5.69973C3.29805 8.55684 3.29805 11.4432 3.63221 14.3003C3.76064 15.3983 4.64855 16.273 5.75697 16.3969C8.55351 16.7094 11.4465 16.7094 14.243 16.3969C15.3515 16.273 16.2394 15.3983 16.3678 14.3003Z"
                fill="black"/>
          </svg>
        </button>

      </div>
    </div>
  </div>


  <teleport to="body">
    <div class="frame-modal-wrapper order-address-select" :class="{'opened': isAddressOpen}">
      <div class="modal-wrapper-holder">
        <div class="frame-modal-header">
          <div class="name h2">Оберіть адресу</div>
          <a href="javascript:void(0);" class="close-cart" @click="isAddressOpen = false">
            <img src="/images/icons/close.svg" alt="">
          </a>
        </div>
        <div class="frame-modal-body">
          <div class="search-input">
            <img src="/images/icons/long-arrow-right.svg" alt="">
            <input type="text" id="query" class="form-control custom-input" ref="query" placeholder="Адреса" v-model="query">
          </div>
          <div class="address-list">
            <ul>
              <li class="address-item" v-for="(i, index) in foundList" :key="index">
                <a href="javascript:void(0)" @click="setAddress(i)">
                  <img src="/images/icons/pin-minimal.svg" alt="">
                  <span class="address-text">
                    <span class="address-name" v-if="i.name">{{ i.name }}</span>
                    <span class="subtitle">{{ i.display_name }}</span>
                  </span>
                </a>
              </li>
              <div v-if="loading">loading</div>
              <div v-if="noResult">noresult</div>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </teleport>
</template>

<style scoped>
.center-pin {
  position  : absolute;
  left      : 50%;
  top       : 50%;
  z-index   : 11111;
  transform : translate(-50%, -100%);
}

.map-holder {
  position : relative;
}
</style>

<script>
export default {
  name : 'MapSelector',
  props: {
    point      : {
      type   : Object,
      default: () => {
        return undefined
      }
    },
    storehouses: {
      type   : Array,
      default: () => {
        return []
      }
    },
    modelValue : {
      type   : Object,
      default: () => {
        return undefined
      }
    }
  },
  data() {
    return {
      map: undefined,
      isAddressOpen: false,
      noResult: false,
      loading: false,
      foundList    : [],
      saved        : undefined,
      mCheck       : undefined,
      open         : false,
      dragged      : false,
      query        : '',
      reverseResult: '',
      timer        : undefined
    }
  },
  computed: {
    mapHeight() {
      return this.open ? '60vh' : '200px'
    },
    myPlaceholder() {
      return this.reverseResult ? this.reverseResult : '...'
    },
    myPoint() {
      if (this.modelValue) { return Object.assign({zoom: 14}, this.modelValue)}
      if (this.point) {return this.point}
      let pos   = this.$store.getters.getPosition
      let point = undefined
      if (pos && pos.loc) {
        let [lat, lng] = pos.loc.split(',')
        if (lat && lng) {
          point = {lat: lat, lng: lng, zoom: 10, zone: []}
        }
      }
      return point
    }
  },
  emits   : ['update:modelValue'],
  mounted() {
    this.mCheck = document.querySelector('#map');
    this.makeMap(this.myPoint)
    if (this.modelValue) {
      this.getReverse(this.modelValue)
    }
    this.closeMap()
  },
  unmounted() {
    if (this.map){
      this.map.off();
      this.map.remove();
      this.map = undefined;
    }
  },
  methods: {
    openAddress() {
      this.isAddressOpen = true
      setTimeout(() => {
        this.$refs.query.focus()
      }, 100)
    },
    setAddress(address){
      let lat = address.lat
      let lng = address.lon

      console.log('address', address)

      this.map.setView(new window.L.LatLng(lat, lng), this.map.zoom, { animation: true });
      this.reverseResult = address.display_name ? address.display_name : address.name
      this.$emit('update:modelValue', Object.assign({}, this.modelValue, {lat: lat, lng: lng, address: this.myPlaceholder}));
      this.isAddressOpen = false
      // this.map = window.L.map('map').setView([ltn, lng], point.zoom)
    },
    getQuery() {
      let params = {street: this.query, county: 'ua', format: 'json'}
      fetch('https://nominatim.openstreetmap.org/search?' + new URLSearchParams(params))
          .then(response => response.json())
          .then(json => {
            this.foundList = json
            this.loading = false
            this.noResult = !(Array.isArray(json) && json.length)
          })
    },
    getReverse(point) {
      let params = {lat: point.lat, lon: point.lng, format: 'json'}
      fetch('https://nominatim.openstreetmap.org/reverse?' + new URLSearchParams(params))
          .then(response => response.json())
          .then(json => {
            if (json.display_name) {
              this.reverseResult = json.display_name
              this.$emit('update:modelValue', Object.assign({}, this.modelValue, {address: json.display_name}));
            }
          })
    },
    openMap() {
      if (this.modelValue) {
        this.saved = JSON.parse(JSON.stringify(this.modelValue))
      }
      this.open = true
      this.map.dragging.enable();
      this.map.scrollWheelZoom.enable();
      let y = document.getElementById('scrollOnEdit').offsetTop + 100
      window.scroll({
                      top     : y,
                      behavior: 'smooth'
                    });
    },
    closeMap() {
      this.open = false
      this.map.dragging.disable();
      this.map.scrollWheelZoom.disable();
    },
    cancel() {
      this.closeMap();
      if (this.saved) {
        this.$emit('update:modelValue', this.saved);
        setTimeout(() => {
          this.map.setView([this.saved.lat, this.saved.lng])
        }, 40)
        this.getReverse(this.saved)
      }
    },
    makeMap(point) {
      if (this.map) {
        this.map.off();
        this.map.remove();
      }
      this.map = window.L.map('map').setView([point.lat, point.lng], point.zoom)
      // this.map.addControl(new window.L.Control.Fullscreen());

      this.map.on('drag', (e) => {
        this.dragged = true
      })
      this.map.on('dragend', (e) => {
        this.dragged = false
        this.getReverse(this.map.getCenter())
        this.$emit('update:modelValue', Object.assign({}, this.modelValue, this.map.getCenter()));
        // if (this.marker){
        //   this.marker.off()
        //   this.marker.remove()
        // }
        // this.marker = window.L.marker(this.map.getCenter(), {draggable: false}).addTo(this.map);

        // console.log('dragend', this.map.getCenter());
      });
      // this.map.on('drag', (e) => {
      //   this.dragged = true
      //   console.log('drag', this.map.getCenter());
      // });

      if (this.storehouses?.length) {
        this.storehouses.forEach(store => {
          if (store?.info?.point) {
            if (store?.info?.icon?.length) {
              let storePoint = window.L.icon({
                                               iconUrl  : store.info.icon[0],
                                               className: 'storehouse-marker',
                                               iconSize : [40, 40] // size of the icon
                                               // iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
                                               // shadowAnchor: [4, 62],  // the same for the shadow
                                               // popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
                                             });
              window.L.marker(store?.info?.point, {draggable: false, icon: storePoint}).addTo(this.map);
            } else {
              window.L.marker(store?.info?.point, {draggable: false}).addTo(this.map);
            }
          }
        })
      }

      window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);

      // window.L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      //   maxZoom   : 20,
      //   subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      // }).addTo(this.map);

      // let options = {
      //   position: 'topright',
      //   geocoder: new window.L.Control.Geocoder.nominatim(
      //       {
      //         geocodingQueryParams: {
      //           'countrycodes': 'ua'
      //         }
      //       })
      // };
      //
      // this.geocoder = window.L.Control.geocoder(options).addTo(this.map);
      // this.geocoder.on('markgeocode', (event) => {
      //   this.$emit('update:modelValue', Object.assign(this.modelValue, event.geocode.center));
      //   window.L.marker(event.geocode.center, {draggable:'true'}).addTo(this.map);
      //   this.map.setView(event.geocode.center, this.map.getZoom());
      // })
    }
  },
  watch  : {
    point(val) {
      if (!this.modelValue && val) {
        this.makeMap(val)
      }
    },
    query() {
      this.loading = true
      this.noResult = false
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getQuery()
      }, 800);
    },
    open() {
      this.timer = setTimeout(() => {
        this.map.invalidateSize()
      }, 30);
    }
  }
}
</script>

<style>
.storehouse-marker {
  border-radius : 50%;
  border        : 3px solid #000000;
}

.placeholder {
  cursor      : pointer;
  line-height : 40px;
  color       : #888888 !important;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
</style>