<template>
  <div class="lk-wrapper wrapper"><!--if not logged in add class .not-logged-->
    <div class="logged-in desktop-hidden mobile-avatar">
      <div class="logged-in-body" v-if="userInfo">
        <span class="user-avatar image bg-cover" v-if="userInfo.photo_url"
              :style="{'background-image': 'url('+ userInfo.photo_url +')'}"></span>
        <span class="user-avatar" v-else-if="userInfo.first_name">{{ userInfo.first_name.substring(0, 1) }}</span>
        <span class="user-avatar image bg-cover" v-else-if="userInfo.username">{{ userInfo.username.substring(0, 1) }}</span>
        <div class="avatar-info">
          <div class="name">{{ userInfo.first_name }} {{ userInfo.last_name }}</div>
          <div class="bonus-panel" v-if="account">
            <span>Ваші бонуси: <span class="num">{{ account.bonus }}</span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="personal_area">
<!--      <div class="not-logged-box">-->
<!--        <div class="button-holder">-->
<!--          <button class="default-btn btn-no-icon">Увійти в кабінет</button>-->
<!--        </div>-->
<!--      </div>-->
      <div class="personal_area-sidebar hidden-on-mobile">
        <div class="sidebar-name h2 desktop-hidden">Особистий кабінет</div>
        <a href="javascript:void(0);" class="close-personal-sidebar desktop-hidden" @click="closeSideBar">
          <img src="/images/icons/close.svg" alt="">
        </a>
        <div class="personal-area-sidebar-holder">
          <div class="logged-in" v-if="userInfo">
            <div class="logged-in-body">
            <span class="user-avatar image bg-cover" v-if="userInfo.photo_url"
                  :style="{'background-image': 'url('+ userInfo.photo_url +')'}"></span>
              <span class="user-avatar" v-else-if="userInfo.first_name">{{ userInfo.first_name.substring(0, 1) }}</span>
              <span class="user-avatar" v-else-if="userInfo.username">{{ userInfo.username.substring(0, 1) }}</span>
              <div class="avatar-info">
                <div class="name">{{ userInfo.first_name }} {{ userInfo.last_name }}</div>
                <div class="bonus-panel" v-if="account">
                  <span>Ваші бонуси: <span class="num">{{ account.bonus }}</span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="personal_area-menu">
            <ul>
              <li>
                <router-link :to="{name: 'CabinetHistory'}" :active-class="'active'">
								<span class="icons-holder">
                  <img src="/images/icons/history.svg" alt="">
                </span>
                  <span class="text">Історія замовлень</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'CabinetInfo'}" :exact-active-class="'active'">
                <span class="icons-holder">
                  <img src="/images/icons/user.svg" alt="">
                </span>
                  <span class="text">Особисті дані</span>
                </router-link>
              </li>
              <!--              <li>-->
              <!--                <router-link :to="{name: 'CabinetDelivery'}" :active-class="'active'">-->
              <!--									<span class="icons-holder">-->
              <!--                    <img src="/images/icons/loc.svg" alt="">-->
              <!--                  </span>-->
              <!--                  <span class="text">Налаштування доставки</span>-->
              <!--                </router-link>-->
              <!--              </li>-->
            </ul>

          </div>
          <div class="logout" v-if="!isWebApp">
            <a href="javascript:void(0)" @click="logout">
              <span class="icons-holder">
                <img src="/images/icons/logout.svg" alt="">
              </span>
              <span class="text">Вийти з аккаунту</span>
            </a>
          </div>
<!--          <div class="button-holder">-->
<!--            <button class="default-btn btn-no-icon">Увійти в кабінет</button>-->
<!--          </div>-->
        </div>
      </div>
      <!--personal area right side-->
      <div class="personal-area-body">
        <router-view></router-view>
      </div>
      <!--end personal area right side-->
    </div>
  </div>

<!--  <teleport to="body">-->
<!--    <div class="frame-modal-wrapper order-address-select" :class="{'opened': isAddressOpen}">-->
<!--      <div class="modal-wrapper-holder">-->
<!--        <div class="frame-modal-header">-->
<!--          <div class="name h2">Оберіть адресу</div>-->
<!--          <a href="javascript:void(0);" class="close-cart" @click="isAddressOpen = false">-->
<!--            <img src="/images/icons/close.svg" alt="">-->
<!--          </a>-->
<!--        </div>-->
<!--        <div class="frame-modal-body">-->
<!--          <div class="search-input">-->
<!--            <img src="/images/icons/long-arrow-right.svg" alt="">-->
<!--            <input type="text" id="query" class="form-control custom-input" ref="query" placeholder="Адреса" v-model="query">-->
<!--          </div>-->
<!--          <div class="address-list">-->
<!--            <ul>-->
<!--              <li class="address-item" v-for="(i, index) in foundList" :key="index">-->
<!--                <a href="javascript:void(0)" @click="setAddress(i)">-->
<!--                  <img src="/images/icons/pin-minimal.svg" alt="">-->
<!--                  <span class="address-text">-->
<!--                    <span class="address-name" v-if="i.name">{{ i.name }}</span>-->
<!--                    <span class="subtitle">{{ i.display_name }}</span>-->
<!--                  </span>-->
<!--                </a>-->
<!--              </li>-->
<!--              <div v-if="loading">loading</div>-->
<!--              <div v-if="noResult">noresult</div>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
<!--  </teleport>-->
</template>

<script>

export default {
  name      : 'Cabinet',
  components: {},
  data() {
    return {}
  },
  computed: {
    account() {
      return this.$store.getters.getAccount
    },
    isWebApp() {
      return this.$store.getters.isWebApp
    },

    baseUserInfo() {
      return this.$store.getters.getUserInfo
    },
    userInfo() {
      return this.account?.telegram ? this.account?.telegram : this.baseUserInfo
    }
  },
  methods : {
    logout() {
      localStorage.removeItem('user_info')
      this.$store.commit('setAccount', undefined)
      this.$store.commit('setUserInfo', undefined)
      this.$router.push({path: '/'})
      setTimeout(this.$router.go, 300)
    },
    closeSideBar() {
      window.$('.personal_area-sidebar').removeClass('opened');
      window.$('body').removeClass('no-scroll');
      window.$('.personal-sidebar-bg').removeClass('is-visible');
    },
  },
  mounted() {
    (async () => {
      await window.accountLoad
      if (!this.account) {
        this.$router.push({name: 'login'})
      }
    })()
  }
}
</script>


<style scoped>
.center-pin {
  position  : absolute;
  left      : 50%;
  top       : 50%;
  z-index   : 11111;
  transform : translate(-50%, -100%);
}

.map-holder {
  position : relative;
}
</style>