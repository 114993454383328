<template>
  <div class="product-container">
    <div class="product-top-panel">
<!--      <router-link :to="{name: 'catalog', params:{ category: category }}" class="back-link">-->
<!--        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path-->
<!--              d="M7.5 10L7.14645 9.64645L6.79289 10L7.14645 10.3536L7.5 10ZM12.1464 4.64645L7.14645 9.64645L7.85355 10.3536L12.8536 5.35355L12.1464 4.64645ZM7.14645 10.3536L12.1464 15.3536L12.8536 14.6464L7.85355 9.64645L7.14645 10.3536Z"-->
<!--              fill="black"/>-->
<!--        </svg>-->
<!--        <span class="text">{{ $static('item.back', 'Назад в категорію') }}</span>-->
<!--      </router-link>-->
      <a href="javascript:void(0)" @click="$filters.goBack" class="back-link">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M7.5 10L7.14645 9.64645L6.79289 10L7.14645 10.3536L7.5 10ZM12.1464 4.64645L7.14645 9.64645L7.85355 10.3536L12.8536 5.35355L12.1464 4.64645ZM7.14645 10.3536L12.1464 15.3536L12.8536 14.6464L7.85355 9.64645L7.14645 10.3536Z"
              fill="black"/>
        </svg>
        <span class="text">{{ $static('item.back', 'Назад в категорію') }}</span>
      </a>

    </div>

    <div class="product-col-holder">
      <div class="product-images-wrapper">
        <div v-if="item" :class="{'product-images': item.images.length > 1 || videos?.length, 'product-image': item.images.length <= 1}">
          <div class="product-label label-new" v-if="isHot">
            <span class="label-text">New</span>
          </div>
          <div class="product-label label-sale" v-if="item.price_old">
            <div class="tile_label tile_sale">-{{ getDiscount }}%</div>
          </div>
          <ul class="image_list" @mousedown="e => mouseDownHandlerTarget(e, 'image_list')">
            <li v-for="(video, index) in videos" :key="index" class="videoBox">
              <video :src="video.url" aria-hidden="true" playsinline controls muted autoplay loop :type="video.type"></video>
            </li>
            <li v-for="(image,index) in item.images" :key="index">
              <a :href="`${getURLImage(image)}`" @click="fancyClick"
                 data-fancybox="zoom" class="image bg-cover"
                 :style="{'background-image': $filters.backgroundImage(image)}"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="product-wrapper wrapper" v-if="item">
        <div class="product-top-info d-flex align-items-center">
          <div class="availability-holder">
            <div class="availability not-availability" v-if="!isStock">
              <span>{{ $static('item.nema', 'Нема в наявності') }} </span>
            </div>
            <div class="availability in-stock" v-else>
              <span>{{ $static('item.est', 'В наявності ') }}</span>
            </div>
          </div>
          <div class="product-id" v-if="item.article">
            <div class="id-name">{{ $static('item.art-id', 'Артикул: ') }} <span class="id-value">{{ item.article }}</span></div>
          </div>
        </div>

        <div class="product-title-holder">
          <div class="name h1">{{ $dynamic(item, item.title, 'title') }}</div>
          <div class="spicy" v-if="spicy > 0" :class="'spicy'+spicy"></div>
        </div>
        <div class="product-info-options">
          <div class="size-holder" v-if="item.advanced?.modList?.length > 1">
            <div class="custom-label">Розмір:</div>
            <div class="size-radio-group">
              <div class="size-radio" v-for="(m, index) in item.advanced.modList" :key="index">
                <input :id="'item'+item.id+'_'+index" type="radio" name="size-radio1" v-model="order.selectedMod" :value="index">
                <label :for="'item'+item.id+'_'+index">{{ m.name }}</label>
              </div>
            </div>
          </div>
          <div class="info-labels">
            <span class="info-label"><span>{{ weight }}</span></span>
          </div>
        </div>

        <ItemuOption v-for="(option, index) in uOptions" :key="index" :option_key="index" :option="option" @select="selectuOption"/>

        <ItemOption v-for="(option,index) in options" :key="index" :option_key="index" :option="option" :settings="optionSettings"
                    @select="selectOption"/>


        <div class="product-buy-options">
          <div class="price-box d-flex">
            <div class="price-element"> {{ $filters.money(price) }} {{ $store.getters.getSettings.symbol }}</div>
            <div class="price-old" v-if="item.price_old"> {{ $filters.money(price_old) }} {{ $store.getters.getSettings.symbol }}</div>
          </div>
          <div class="nt-spinner">
            <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(-1)"></button>
            <input type="text" class="form-control " v-model="order.count">
            <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(+1)"></button>
          </div>
          <div class="buttons product-buttons" v-if="isStock">
            <button class="default-btn quick_cart_trigger" @click="buy">
              <span class="btn-text">{{ $static('item.buy_', 'Замовити') }}</span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.33301 11.0417C8.67819 11.0417 8.95801 11.3215 8.95801 11.6667V13.3333C8.95801 13.6785 8.67819 13.9583 8.33301 13.9583C7.98783 13.9583 7.70801 13.6785 7.70801 13.3333V11.6667C7.70801 11.3215 7.98783 11.0417 8.33301 11.0417Z"
                    fill="white"/>
                <path
                    d="M12.2913 11.6667C12.2913 11.3215 12.0115 11.0417 11.6663 11.0417C11.3212 11.0417 11.0413 11.3215 11.0413 11.6667V13.3333C11.0413 13.6785 11.3212 13.9583 11.6663 13.9583C12.0115 13.9583 12.2913 13.6785 12.2913 13.3333V11.6667Z"
                    fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.04094 2.81248C8.21353 2.51355 8.11111 2.13131 7.81217 1.95872C7.51324 1.78613 7.131 1.88855 6.95841 2.18748L5.29174 5.07423C5.26677 5.11748 5.24756 5.16247 5.23386 5.20832H4.99967C3.73402 5.20832 2.70801 6.23433 2.70801 7.49998C2.70801 8.21592 3.03631 8.85518 3.55059 9.27543L4.10637 13.096L4.47865 14.8403C4.70171 15.8854 5.55487 16.6792 6.61331 16.8265C8.86006 17.1391 11.1393 17.1391 13.386 16.8265C14.4445 16.6792 15.2976 15.8854 15.5207 14.8403L15.893 13.096L16.4488 9.27543C16.963 8.85518 17.2913 8.21592 17.2913 7.49998C17.2913 6.23433 16.2653 5.20832 14.9997 5.20832H14.7655C14.7518 5.16247 14.7326 5.11748 14.7076 5.07423L13.0409 2.18748C12.8684 1.88855 12.4861 1.78613 12.1872 1.95872C11.8882 2.13131 11.7858 2.51355 11.9584 2.81248L13.3416 5.20832H6.6577L8.04094 2.81248ZM15.1109 9.789C15.074 9.79076 15.037 9.79165 14.9997 9.79165H4.99967C4.96239 9.79165 4.92531 9.79076 4.88845 9.789L5.33742 12.8753L5.70112 14.5794C5.81444 15.1103 6.24786 15.5136 6.78558 15.5884C8.91804 15.8851 11.0813 15.8851 13.2138 15.5884C13.7515 15.5136 14.1849 15.1103 14.2982 14.5794L14.6619 12.8753L15.1109 9.789ZM3.95801 7.49998C3.95801 6.92469 4.42438 6.45832 4.99967 6.45832H14.9997C15.575 6.45832 16.0413 6.92469 16.0413 7.49998C16.0413 8.07528 15.575 8.54165 14.9997 8.54165H4.99967C4.42438 8.54165 3.95801 8.07528 3.95801 7.49998Z"
                      fill="white"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="particular">
          <div class="custom-label">
            Особливості
          </div>
          <ul>
            <li v-for="(i, index) in ingredient" :key="index">
              <img v-if="i.image" :src="i.image" alt="">
              <span class="text">{{ i.name }}</span>
            </li>
          </ul>
        </div>
        <div class="product-accordion" id="product_accordion">
          <!--panel description-->
          <div class="panel panel-accordion">
            <div class="panel-title">
              <a data-toggle="collapse" data-parent="#product_accordion" aria-expanded="true" href="#description" class="label-name">Про
                продукт</a>
            </div>
            <div id="description" class="panel-collapse collapse show" data-parent="#product_accordion">
              <div class="panel-body">
                <div class="desc-text" v-html="item.description">
                </div>
              </div>
            </div>
          </div>
          <!--end panel description-->
        </div>

        <Teleport to="body">
          <div class="fixed-options-panel">
            <div class="product-buy-options">
              <div class="price-box">
                <div class="price-element"> {{ $filters.money(price) }} {{ $store.getters.getSettings.symbol }}</div>
                <div class="price-old" v-if="item.price_old"> {{ (price_old) }} {{ $store.getters.getSettings.symbol }}</div>
              </div>

              <div class="btns d-flex" v-if="isStock">
                <div class="nt-spinner">
                  <button class=" nt-btn btn_less icons icon-minus" type="button" @click="addCount(-1)"></button>
                  <input type="text" class="form-control " v-model="order.count">
                  <button class=" nt-btn btn_more icons icon-plus" type="button" @click="addCount(+1)"></button>
                </div>
                <button class="default-btn quick_cart_trigger"  @click="buy">
                  <span class="btn-text">{{ $static('item.buy_', 'Замовити') }}</span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.33301 11.0417C8.67819 11.0417 8.95801 11.3215 8.95801 11.6667V13.3333C8.95801 13.6785 8.67819 13.9583 8.33301 13.9583C7.98783 13.9583 7.70801 13.6785 7.70801 13.3333V11.6667C7.70801 11.3215 7.98783 11.0417 8.33301 11.0417Z"
                        fill="white"/>
                    <path
                        d="M12.2913 11.6667C12.2913 11.3215 12.0115 11.0417 11.6663 11.0417C11.3212 11.0417 11.0413 11.3215 11.0413 11.6667V13.3333C11.0413 13.6785 11.3212 13.9583 11.6663 13.9583C12.0115 13.9583 12.2913 13.6785 12.2913 13.3333V11.6667Z"
                        fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.04094 2.81248C8.21353 2.51355 8.11111 2.13131 7.81217 1.95872C7.51324 1.78613 7.131 1.88855 6.95841 2.18748L5.29174 5.07423C5.26677 5.11748 5.24756 5.16247 5.23386 5.20832H4.99967C3.73402 5.20832 2.70801 6.23433 2.70801 7.49998C2.70801 8.21592 3.03631 8.85518 3.55059 9.27543L4.10637 13.096L4.47865 14.8403C4.70171 15.8854 5.55487 16.6792 6.61331 16.8265C8.86006 17.1391 11.1393 17.1391 13.386 16.8265C14.4445 16.6792 15.2976 15.8854 15.5207 14.8403L15.893 13.096L16.4488 9.27543C16.963 8.85518 17.2913 8.21592 17.2913 7.49998C17.2913 6.23433 16.2653 5.20832 14.9997 5.20832H14.7655C14.7518 5.16247 14.7326 5.11748 14.7076 5.07423L13.0409 2.18748C12.8684 1.88855 12.4861 1.78613 12.1872 1.95872C11.8882 2.13131 11.7858 2.51355 11.9584 2.81248L13.3416 5.20832H6.6577L8.04094 2.81248ZM15.1109 9.789C15.074 9.79076 15.037 9.79165 14.9997 9.79165H4.99967C4.96239 9.79165 4.92531 9.79076 4.88845 9.789L5.33742 12.8753L5.70112 14.5794C5.81444 15.1103 6.24786 15.5136 6.78558 15.5884C8.91804 15.8851 11.0813 15.8851 13.2138 15.5884C13.7515 15.5136 14.1849 15.1103 14.2982 14.5794L14.6619 12.8753L15.1109 9.789ZM3.95801 7.49998C3.95801 6.92469 4.42438 6.45832 4.99967 6.45832H14.9997C15.575 6.45832 16.0413 6.92469 16.0413 7.49998C16.0413 8.07528 15.575 8.54165 14.9997 8.54165H4.99967C4.42438 8.54165 3.95801 8.07528 3.95801 7.49998Z"
                          fill="white"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Teleport>
      </div>
    </div>
    <!--    <div class="product-info wrapper" v-if="item">-->
    <!--      &lt;!&ndash;product info accordion&ndash;&gt;-->
    <!--      -->
    <!--    </div>-->

    <div class="related-products" v-if="upsaleItems && upsaleItems.length">
      <div class="name h2">{{ $static('item.related', 'З цим часто купують:') }}</div>
      <ul class="catalog-grid" @mousedown="e => mouseDownHandlerTarget(e, 'catalog-grid')">
        <li class="catalog-item" v-for="(item,index) in upsaleItems" :key="index">
          <UpsaleItem :item="item"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ItemuOption from '@/views/ItemuOption';
import UpsaleItem from '@/views/UpsaleItem';
import axios from 'axios'
import ItemOption from './ItemOption';

export default {
  name      : 'Item',
  components: {UpsaleItem, ItemuOption, ItemOption},
  props     : ['category', 'id'],
  data() {
    return {
      item       : undefined,
      upsaleItems: [],
      order      : {
        count          : 1,
        selectedMod    : 0,
        additionSelect : [],
        additionUSelect: []
      }
    }
  },
  mounted() {
    this.getItem();

    window.scrollTo(0, 0);

    window.$('.product-images a').fancybox();

    // window.$(`a[href="/catalog/${this.category}"]`).addClass('active');

    window.$('.frame-modal-wrapper-bg').on('click', function () {
      window.$(this).removeClass('is-visible');
      window.$('body').removeClass('no-scroll');

      window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
      window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
      return false;
    });
  },
  methods : {
    fancyClick(e) {
      if (window.change) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    mouseDownHandlerTarget(e, targetClass) {
      let target = e.target;
      while (!target.className.includes(targetClass)) {
        target = target.parentElement
      }
      window.ele = target;
      window.mouseDownHandler(e)
    },
    addCount(i) {
      if (i + this.order.count) {
        this.order.count = Math.max(this.order.count + i, 1)
      }
    },

    selectOption({item, status}) {
      if (!this.order.additionSelect) {
        this.order.additionSelect = []
      }
      if (status) {
        this.order.additionSelect.push(item)
      } else {
        let idx = this.order.additionSelect.findIndex(x => x.id == item.id);
        if (idx > -1) {
          this.order.additionSelect.splice(idx, 1)
        }
      }
    },
    selectuOption({item, status}) {
      if (!this.order.additionUSelect) {
        this.order.additionUSelect = []
      }
      if (status) {
        this.order.additionUSelect.push(item)
      } else {
        let idx = this.order.additionUSelect.findIndex(x => x._id == item._id);
        if (idx > -1) {
          this.order.additionUSelect.splice(idx, 1)
        }
      }
    },
    buy() {
      let idx = -1;
      idx     = this.$store.getters.getBuskedSelected.findIndex(i => i.id === this.item.id);
      if (idx > -1) {
        // this.basked[idx].count += this.order.count;
        this.$store.commit(
            'replaceBuskedIndex',
            {index: idx, value: JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))}
        );
      } else {
        // console.log(JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item, {sizeSelect: this.sizeSelected}))));
        let item = JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))
        this.$store.commit(
            'addToBasked',
            item
        );
        this.$AddToCart(item)
      }
      window.$('body').addClass('no-scroll');
      window.$('#quick_cart').addClass('opened');
      window.$('.frame-modal-wrapper-bg').addClass('is-visible');
    },
    getURLImage(image) {
      if (image && image.includes('https://')) {
        return image
      }
      return `${window.location.protocol}//${window.location.host}${image}`
    },

    getItem() {
      return axios.get(`/public/item/${this.id}`)
          .then(response => response.data)
          .then(data => {
            if (data.result) {
              this.item = data.result;
              this.$ViewItem(this.item);
              this.getUpsaleItems()
            }
            if (data.error === 'not found') {
              this.$router.push({name: 'NotFound'});
            }
            return
          }).then(() => {
            if (this.item && this.item.advanced) {
              let title = this.item.advanced.meta_title ? this.item.advanced.meta_title : this.$dynamic(
                  this.item,
                  this.item.title,
                  'title'
              );

              document.querySelector('title').innerText = title;
              document.querySelector('meta[property="og:title"]').setAttribute('content', title);

              let description = this.item.advanced.meta_description;
              document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
              document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');

              let image = this.item.preview ? `${window.location.protocol}//${window.location.host}${this.item.preview}` : '';
              document.querySelector('meta[property="og:image"]').setAttribute('content', image)
            }
          }).catch(error => {

          })
    },
    getUpsaleItems() {
      if (this.upsale && this.upsale.length) {
        axios.post(`/public/item`, {list: this.upsale.map(i => i.id)})
            .then(response => response.data)
            .then(data => {
              if (data.result) {
                this.upsaleItems = data.result
              }
            })
      }
    }
  },
  computed: {
    videos() {
      return this.item?.advanced?.videos?.length ? this.item.advanced.videos : []
    },
    getDiscount() {
      return this.item.preview ? (1 - Math.max(this.item.price / this.item.price_old, 0)) * 100 | 0 : ''
    },
    isHot() {
      return this.item?.advanced?.hot
    },
    ingredient() {
      return this.item?.advanced?.options?.ingredient ? this.item?.advanced?.options?.ingredient.map(id => (
          this.ingridients?.list.find(x => x.id === id)
      )).filter(i => !!i) : []
    },
    ingridients() {
      return this.$store.getters.getOptions?.ingredient ? this.$store.getters.getOptions.ingredient : {}
    },
    spicy() {
      return this.item?.advanced?.s_level
    },
    weight() {
      return this.item?.advanced?.modList?.length ? this.item.advanced.modList[this.order.selectedMod].gram : ''
    },
    consultant() {
      return this.settings && this.settings.consultant ? this.settings.consultant : false
    },

    settings() {
      return this.$store.getters.getSettings
    },
    options() {
      return this.item && this.item.advanced && this.item.advanced.options ? this.item.advanced.options : []
    },
    uOptions() {
      return this.item && this.item.advanced && this.item.advanced.uOption ? this.item.advanced.uOption : []
    },
    optionSettings() {
      return this.$store.getters.getOptionSettings ? this.$store.getters.getOptionSettings : []
    },
    price() {
      if (this.order.selectedMod > 0) {
        return this.item?.advanced?.modList[this.order.selectedMod].price ?
            this.item.advanced.modList[this.order.selectedMod].price + this.addition_price + this.addition_Uprice : 0
      }
      return (this.item.price + this.addition_price + this.addition_Uprice) * this.order.count
    },
    price_old() {
      if (this.order.selectedMod > 0) {
        return this.item?.advanced?.modList[this.order.selectedMod].price_old ?
            parseFloat(this.item.advanced.modList[this.order.selectedMod].price_old) + this.addition_price + this.addition_Uprice : 0
      }
      return this.item.price_old + this.addition_price + this.addition_Uprice
    },
    upsale() {
      return this.item && this.item.advanced && this.item.advanced.upsale ? this.item.advanced.upsale : []
    },
    addition_Uprice() {
      return this.order.additionUSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
    },
    addition_price() {
      return this.order.additionSelect.reduce((c, value) => value && value.price ? c + parseFloat(value.price) : c, 0)
    },
    basked() {
      return this.$store.state.busked
    },
    isStock() {
      return this.item && this.item.stock
    }
  },
  watch   : {
    id() {
      this.getItem();
      this.order = {
        count          : 1,
        selectedMod    : 0,
        additionSelect : [],
        additionUSelect: []
      };

      // window.$(`.category-panel-holder a.active`).removeClass('active');
      // window.$(`a[href="/catalog/${this.category}"]`).addClass('active');

      window.scrollTo(0, 0);

      window.$('.product-images a').fancybox();

      window.$('.frame-modal-wrapper-bg').on('click', function () {
        window.$(this).removeClass('is-visible');
        window.$('body').removeClass('no-scroll');

        window.$('.frame-modal-list .first-level-item .level-sub').removeClass('open-level');
        window.$('#sort_filter,#sorting,#category_panel,#quick_cart').removeClass('opened');
        return false;
      });
    }
  }

}
</script>

<style scoped>
.videoBox{
  text-align: center;
  position: relative;
  padding-left: 75%;
}

.videoBox video{
  max-width: 100%;
  max-height: 100%;
  z-index: 11;
  width: 100%;
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
}
</style>
