<template>
  <div class="title-heading">
    <h1>Налаштування доставки</h1>
  </div>
  <div class="personal-delivery  d-none">
    <div class="personal-delivery-holder">
      <div class="map-wrapper" style="height: 200px;background-color: #f5f5f5;border-radius: 20px;">

      </div>
      <div class="panel-bordered delivery-form">
        <div class="personal-delivery-left">
          <div class="form-group">
            <label class="label-custom">Під‘їзд <span>*</span></label>
            <input type="text" class="form-control custom-input" placeholder="Введіть номер під’їзду">
          </div>
          <div class="form-inline">
            <div class="form-group">
              <label class="label-custom">Поверх <span>*</span></label>
              <input type="text" class="form-control custom-input">
            </div>
            <div class="form-group">
              <label class="label-custom">Квартира</label>
              <input type="text" class="form-control custom-input">
            </div>
          </div>
        </div>
        <div class="personal-delivery-right">
          <div class="form-group mb-0">
            <label class="label-custom">Коментар для кур’єра</label>
            <textarea class="form-control custom-input" placeholder="Текст повідомлення"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="button-holder">
      <button class="default-btn btn-no-icon">Зберегти зміни</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CabinetDelivery'
}
</script>
