<template>
  <div class="catalog-wrapper wrapper">
    <div class="custom-header category-header bg-cover" v-if="categoryImage" :style="{'background-image': $filters.backgroundImage(categoryImage)}">
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="category-header custom-header min-header" v-else>
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="cat-title">
      <h1>{{ $dynamic(categoryObject, categoryName, 'name') }}</h1>
    </div>
    <div class="sorting-panel" v-if="tags && tags.length">
      <div class="custom-label">
        {{ $static('catalog.title', 'Оберіть своє:') }}
      </div>
      <div class="sort-list">
        <div class="sorting-checkbox">
          <input id="all" type="checkbox" v-model="all_tags" value="1">
          <label for="all">
            <span class="text">{{ $static('options.all', 'Усі') }}</span>
          </label>
        </div>

        <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
          <input :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
          <label :for="op.id">
            <span class="pick" v-if="op.image" :style="{'background-image': $filters.backgroundImage(op.image)}"></span>
            <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
            <span class="text">
              <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
              <span class="custom-option-price">
                <span class="price" v-if="op.price"> {{ $filters.money(op.price) }} {{ $store.getters.getSettings.symbol }}</span>
                <span class="price price-old"
                      v-if="op.price_old"> {{ $filters.money(op.price_old) }} {{ $store.getters.getSettings.symbol }}</span>
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="catalog-grid-holder">
      <ul class="catalog-grid">
        <CatalogItem v-for="(item,index) in items" :key="index" :item="item" :category-object="categoryObject" />
        <div ref="loadMore"></div>
      </ul>
    </div>
  </div>
</template>

<script>
import CatalogItem from '@/views/CatalogItem';
import axios from 'axios';

export default {
  name : 'Catalog',
  components: {CatalogItem},
  props: ['category'],
  data() {
    return {
      optionSelect: [],
      items       : [],
      load        : false,
      page        : 1,
      perPage     : 12,
      total       : 0,
      more        : true,
      checkLoader : undefined,
      loadBefore  : 0
    }
  },
  beforeUnmount() {
    window.document.removeEventListener('scroll', this.onscroll);
  },
  updated() {
    if (this.more) {
      this.loadBefore = this.checkLoader.offsetTop;
    } else {
      let body        = document.body, html = document.documentElement;
      this.loadBefore = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 1000;
    }
  },
  mounted() {
    this.checkLoader = this.$refs.loadMore;
    window.document.addEventListener('scroll', this.onscroll);

    this.getItems();
    this.setSeo(this.categoryObject);

    this.$ViewCategory(this.categoryObject)
  },
  methods : {
    onscroll(e) {
      if (document.documentElement.scrollTop + window.innerHeight > this.loadBefore && this.more) {
        this.page += 1;
        this.getItems();
      }
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    getItems(clear) {
      if (this.load) {
        return
      }
      this.load = true;
      if (clear) {
        this.page = 1;
        this.more = true;
      }
      let filter = {page: this.page, perPage: this.perPage};
      if (this.categoryName) {
        filter.category = this.categoryObject.id
      } else {
        filter.url = this.category
      }

      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).filter(i => !!i).map(x => x ? x.id : x);
      }
      axios.post('/public/item/filter', {filter: filter, sortBy: 'sort'}).then(response => response.data).then(data => {
        this.load = false;
        if (clear) {
          this.items = data.result;
        } else {
          this.items.push(...data.result);
        }
        this.page  = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
      }).catch(e => {
        this.load = false;
      })
    },

    setSeo(val) {
      if (val && val.info) {
        let title = val.info.title ? val.info.title : this.$dynamic(this.categoryObject, this.categoryName, 'name');

        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = val.info.description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');
        let image = val.image ? `${window.location.protocol}//${window.location.host}${val.image}` : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  },
  computed: {
    // tags() {
    //   return this.$store.getters.getTags.filter(x => x.info ? !x.info.hidden : true)
    // },
    tags() {
      return this.categoryObject &&
      this.categoryObject.info &&
      this.categoryObject.info.tags ?
          this.categoryObject.info.tags.map(id => this.$store.getters.getTags.find(i => i.id === id)).filter(i => !!i) : []
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    categoryObject() {
      return this.$store.getters.getCategories.find(i => i.info.url === this.category)
    },
    categoryName() {
      return this.categoryObject ? this.categoryObject.name : ''
    },
    categoryImage() {
      return this.categoryObject && this.categoryObject.image ? this.categoryObject.image : ''
    }
  },
  watch   : {
    category() {
      this.$ViewCategory(this.categoryObject)
      this.optionSelect = []

      // this.getItems(true)
    },
    optionSelect() {
      this.getItems(true)
    },
    categoryObject(val) {
      this.setSeo(val)
    }
  }
}
</script>

