<template>

  <div class="custom-swiper-container">
    <div class="swiper custom-swiper about-top-slider">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="text">
              <a href="javascript:void(0)" class="item-link">
                <span>Детальніше</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                      fill="black"/>
                </svg>
              </a>
            </div>
            <a href="#" class="image-holder">
              <img src="/images/landing/top-slider/2.png" alt="">
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="text">
              <a href="javascript:void(0)" class="item-link">
                <span>Детальніше</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                      fill="black"/>
                </svg>
              </a>
            </div>
            <a href="#" class="image-holder">
              <img src="/images/landing/top-slider/1.png" alt="">
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="text">
              <a href="javascript:void(0)" class="item-link">
                <span>Детальніше</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                      fill="black"/>
                </svg>
              </a>
            </div>
            <a href="#" class="image-holder">
              <img src="/images/landing/top-slider/3.png" alt="">
            </a>
          </div>
        </div>

      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
  <div class="main-wrapper wrapper">
    <div class="custom-info">
      <div class="custom-heading">
        <h1>Про нас</h1>
        <img src="images/landing/heading-element.svg" alt="">
        <span class="large-text">About us</span>
      </div>
      <div class="text">
        <p>Подорож дарує натхнення, відкриває нові унікальні можливості та мотивує нас ставати кращими.</p>
        <p><span class="bold">SomeName</span> – це кухня вражень, гастро-подорож із сильним характером та ніжним смаком.
        </p>

      </div>
      <div class="text custom-mobile-hidden">
        <p>Авторське меню Шеф-кухаря, гастромандрівника Володимира Ярославського, різноманітна і багата класичними
          стравами з усього світу.
          А власна характерна інтерпретація найкращих рецептів та унікальна подача страв перетворюють традиційну кухню
          на витвір мистецтва.Корисна і поживна
          італійська кухня, вишукані страви, приготовані з особливою французькою ретельністю і увагою до деталей, лежать
          в основі
          авторського підходу «Contemporary Kitchen» Володимира Ярославського.</p>
      </div>
      <div class="custom-mobile-visible">
        <a class="default-link" data-toggle="collapse" href="#more_about" role="button" aria-expanded="false" aria-controls="more_about">
          <span class="text">Читати повністю</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 7.5L10 12.5L15 7.5" stroke="#171717"/>
          </svg>
        </a>
        <div class="collapse" id="more_about">
          <p>Авторське меню Шеф-кухаря, гастромандрівника Володимира Ярославського, різноманітна і багата класичними
            стравами з усього світу.
            А власна характерна інтерпретація найкращих рецептів та унікальна подача страв перетворюють традиційну кухню
            на витвір мистецтва.Корисна і поживна
            італійська кухня, вишукані страви, приготовані з особливою французькою ретельністю і увагою до деталей, лежать
            в основі
            авторського підходу «Contemporary Kitchen» Володимира Ярославського.</p>
        </div>
      </div>
    </div>
    <div class="custom-info custom-info-with-img">
      <div class="image">
        <img src="images/landing/team.jpg" alt="">
      </div>
      <div class="content">
        <div class="custom-heading">
          <h2 class="h1">Наша команда</h2>
          <img src="images/landing/heading-element.svg" alt="">
          <span class="large-text">Our Team</span>
        </div>
        <div class="text">
          <p>Подорож дарує натхнення, відкриває нові унікальні можливості та мотивує нас ставати кращими.</p>
          <p>Авторське меню Шеф-кухаря, гастромандрівника Володимира Ярославського, різноманітна і багата класичними
            стравами з усього світу.
            А власна характерна інтерпретація найкращих рецептів та унікальна подача страв перетворюють традиційну кухню
            на витвір мистецтва.</p>
        </div>
      </div>
    </div>
    <div class="menu-cheaf">
      <div class="dishes-list">
        <div class="custom-heading">
          <h2 class="h1">Меню</h2>
          <img src="images/landing/heading-element.svg" alt="">
        </div>
        <div class="dish-item catalog-item item-alt">
          <div class="value-info"><span class="value">300 г</span></div>
          <img src="images/landing/dish1.png" class="item-img" alt="">
          <div class="item-content">
            <a href="#" class="default-link">
              <span class="text">Замовити</span>
              <img src="/images/icons/right.svg">
            </a>
            <div class="product-name">
              Боул “Фантазія”
            </div>
          </div>
        </div>
        <div class="dish-item catalog-item">
          <div class="value-info"><span class="value">300 г</span></div>
          <img src="images/landing/dish2.png" class="item-img" alt="">
          <div class="item-content">
            <div class="product-name">
              Боул “Фантазія”
            </div>
            <a href="#" class="default-link">
              <span class="text">Замовити</span>
              <img src="/images/icons/right.svg">
            </a>

          </div>
        </div>
        <div class="dish-decor">Best Deals</div>
      </div>
      <div class="cheaf-side">
        <div class="cheaf-label">
          Cheaf
        </div>
        <div class="image-holder">
          <img src="images/landing/cheaf.jpg" alt="">
        </div>
        <div class="cheaf-info">
          <span>Шеф-повар</span>
          <p>Володимир Ярославський</p>
        </div>
      </div>
    </div>
  </div>

  <div class="custom-swiper-container">
    <div class="main-wrapper wrapper">
      <div class="custom-heading">
        <h2  class="h1">Фірмові страви від Шефа</h2>
        <img src="images/landing/heading-element.svg" alt="">
      </div>
    </div>
    <div class="swiper custom-swiper cheaf-dishes-slider">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="product-label-holder">
              <div class="product-label label-with-icon label-important">
                <img src="/images/icons/cheaf-hat.svg">
                <span class="label-text">Від шеф-кухаря</span>
              </div>
            </div>
            <a href="#" class="image-holder">
              <img src="/images/landing/cheaf-dishes/1.jpg" alt="">
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="product-label-holder">
              <div class="product-label label-with-icon label-important">
                <img src="/images/icons/cheaf-hat.svg">
                <span class="label-text">Від шеф-кухаря</span>
              </div>
            </div>
            <a href="#" class="image-holder">
              <img src="/images/landing/cheaf-dishes/2.jpg" alt="">
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <div class="product-label-holder">
              <div class="product-label label-with-icon label-important">
                <img src="/images/icons/cheaf-hat.svg">
                <span class="label-text">Від шеф-кухаря</span>
              </div>
            </div>
            <a href="#" class="image-holder">
              <img src="/images/landing/cheaf-dishes/3.jpg" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
  <div class="main-wrapper wrapper">

    <div class="custom-heading">
      <h2 class="h1">Афіша</h2>
      <img src="images/landing/heading-element.svg" alt="">
    </div>
    <div class="swiper custom-swiper events-slider">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <a href="#" class="image-holder">
              <img src="/images/landing/event.jpg" alt="">
            </a>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="carousel-item-body">
            <a href="#" class="image-holder">
              <img src="/images/landing/event.jpg" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="custom-contacts">
      <div class="custom-heading">
        <h2  class="h1">Наші ресторани</h2>
        <img src="images/landing/heading-element.svg" alt="">
      </div>
      <div class="custom-contacts-box">
        <div class="custom-contacts-info">
          <div class="custom-contacts-item">
            <div class="item-label">адреса:</div>
            <div class="text">Україна, 61031, Харківська обл., місто Харків, вул. Ромашкіна, будинок 8 А, квартира 101
            </div>
          </div>
          <div class="custom-contacts-item">
            <div class="item-label">Робочі години:</div>
            <div class="text">з <b>9:00</b> до <b>20:00</b> щоденно</div>
          </div>
          <div class="custom-contacts-item buttons-item">
            <div class="item-label">телефони:</div>
            <ul class="buttons-list">
              <li>
                <a href="#" class="default-btn btn-outline-light btn-alt">
                  <img src="/images/icons/phone.svg" alt="">
                  <span class="text">+38 099 999 99 99</span>
                </a>
              </li>
              <li>
                <a href="#" class="default-btn btn-outline-light btn-alt">
                  <img src="/images/icons/phone.svg" alt="">
                  <span class="text">+38 099 999 99 99</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="custom-contacts-item buttons-item">
            <div class="item-label">Соціальні мережі:</div>
            <ul class="buttons-list">
              <li>
                <a href="#" class="default-btn btn-outline-light btn-alt">
                  <img src="/images/icons/telegram-outline.svg" alt="">
                  <span class="text">Telegram</span>
                </a>
              </li>
              <li>
                <a href="#" class="default-btn btn-outline-light btn-alt">
                  <img src="/images/icons/insta.svg" alt="">
                  <span class="text">Instagram</span>
                </a>
              </li>
              <li>
                <a href="#" class="default-btn btn-outline-light btn-alt">
                  <img src="/images/icons/viber.svg" alt="">
                  <span class="text">Viber</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="custom-contacts-map">
          <div class="map-holder" style="background-color: #e9e9e9; height: 460px;"></div>
        </div>
      </div>

    </div>


  </div>
  <noscript id="myscript">
    console.log('myscript');
    let swiper = new window.Swiper('.about-top-slider', {
    centeredSlides: true,
    loop : true,
    spaceBetween : 15,
    slidesPerView : 'auto',
    <!--        speed        : 700,-->
    <!--        autoplay     : {-->
    <!--          delay               : 3000,-->
    <!--          disableOnInteraction: false-->
    <!--        },-->
    breakpoints: {
    320 : {
    slidesPerView : 'auto',
    centeredSlides: false,
    spaceBetween : 15
    },
    575 : {
    slidesPerView : 'auto',
    centeredSlides: false,
    spaceBetween : 15
    },
    768 : {
    slidesPerView : 'auto',
    centeredSlides: true,
    spaceBetween : 15
    },
    1024: {
    slidesPerView: 2,
    centeredSlides: true,
    spaceBetween : 30
    },
    1200: {
    slidesPerView: 2,
    centeredSlides: true,
    spaceBetween : 40
    }
    },
    pagination : {
    el : '.swiper-pagination',
    dynamicBullets: false
    }
    });
    window.afterUnmount.push(() => {swiper.destroy()})

    let swiper2 = new window.Swiper('.cheaf-dishes-slider', {
    centeredSlides: true,
    loop : true,
    spaceBetween : 15,
    slidesPerView : 'auto',
    <!--    speed        : 700,-->
    <!--    autoplay     : {-->
    <!--    delay               : 3000,-->
    <!--    disableOnInteraction: false-->
    <!--    },-->
    breakpoints: {
    320 : {
    slidesPerView : 'auto',
    centeredSlides: false,
    spaceBetween : 15
    },
    575 : {
    slidesPerView : 'auto',
    centeredSlides: false,
    spaceBetween : 15
    },
    768 : {
    slidesPerView : 'auto',
    centeredSlides: true,
    spaceBetween : 15
    },
    1024: {
    slidesPerView: 2,
    centeredSlides: true,
    spaceBetween : 30
    },
    1200: {
    slidesPerView: 2,
    centeredSlides: true,
    spaceBetween : 40
    }
    },
    pagination : {
    el : '.swiper-pagination',
    dynamicBullets: false
    }
    });
    window.afterUnmount.push(() => {swiper2.destroy()})

    let swiper3 = new window.Swiper('.events-slider', {
    slidesPerView: 1,
    loop : true,
    spaceBetween : 15,
    speed : 700,
    autoplay : {
    delay : 3000,
    disableOnInteraction: false
    },
    pagination : {
    el : '.swiper-pagination',
    dynamicBullets: false
    }
    });
    window.afterUnmount.push(() => {swiper3.destroy()})

  </noscript>
</template>

<script>
export default {
  name: 'Landing',
  data() {
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    window.afterUnmount = [];

    let script = document.querySelector('#myscript')
    if (script) {
      eval(script.innerHTML)
    }
  },
  unmounted() {
    window.afterUnmount.forEach(callback => callback())
  }
}
</script>

<style scoped>
@media (max-width: 767px){
  .custom-contacts-box .map-holder{
    height: 250px !important;
  }
}
</style>