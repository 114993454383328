<template>
  <div class="default-wrapper wrapper">
  <div class="not-found">
    <p>Необхідна авторизація. Натисніть "Увійти як" - через телеграмм.</p>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'Not Found',
    data() {},
    mounted() {
      window.$('.personal_area-sidebar').removeClass('open');
      window.$('body').removeClass('no-scroll');
      window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
    }
  }
</script>
