<template>
  <div class="frame-modal-wrapper quick-cart" id="quick_cart">
    <div class="modal-wrapper-holder">

      <div class="frame-modal-header">
        <div class="name h2">{{ $static('busked.title', 'Ваша корзина') }}</div>
        <a href="javascript:void(0);" class="close-cart">
          <img src="/images/icons/close.svg" alt="">
        </a>
      </div>
      <div class="frame-modal-body">
        <div class="order-tab-buttons" v-if="busked.length > 1">
          <ul>
            <li v-for="(_, orderIndex) in busked" :key="orderIndex">
              <a href="javascript:void(0)" @click="buskedSelect(orderIndex)" :class="{'active': selected===orderIndex}">Замовлення #{{ orderIndex + 1 }}</a>
            </li>
          </ul>
        </div>

        <ul class="frame-modal-list">
          <li class="cart-item" v-for="(item,index) in buskedSelected" :key="index">
            <div class="item-holder">
              <a class="remove">
                <img src="/images/icons/delete.svg" alt="" @click.prevent="delBasked(index)">
              </a>
              <div class="item-info">
                <div class="image bg-cover" :style="{'background-image': $filters.backgroundImage(item.preview)}">

                </div>
                <div class="right-side">
                  <router-link :to="{name: 'Item', params:{ category: categoryURL(item), id: item.id}}">
                    {{ item.title }}
                  </router-link>
                  <!--                  <div class="name">{{item.title}}</div>-->
                  <div class="info-labels">
                    <span class="info-label">
                      {{ $static('busked.count', 'Кількість:') }}
                      <span>{{ item.count }}{{$static('busked.nam', 'шт')}}</span>
                    </span>
                  </div>
                  <div class="product-price">
                    <div class="additional-line" v-if="item.price">
                      <div class="add-name">{{ $static('busked.price', 'Ціна') }}</div>
                      <div class="value">{{ $filters.money(price(item)) }} {{ $store.getters.getSettings.symbol }}</div>
                    </div>
                  </div>
                  <div class="additional" v-if="item.additionSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                      <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                      <div class="value" v-if="m.price">{{ $filters.money(m.price) }} {{ $store.getters.getSettings.symbol }}</div>
                    </div>
                  </div>
                  <div class="additional" v-if="item.additionUSelect">
                    <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                      <div class="add-name">{{ $dynamic(m, m.name, 'name') }}</div>
                      <div class="value" v-if="m.price">{{ $filters.money(m.price) }} {{ $store.getters.getSettings.symbol }}</div>
                    </div>
                  </div>
                  <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }} {{ $store.getters.getSettings.symbol }}
                  </div>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
      <div class="frame-back-link">
        <a href="javascript:void(0)" class="back-link">
          <img src="/images/icons/left.svg"> <span class="text">{{ $static('busked.continue', 'Продовжити покупки') }}</span>
        </a>
      </div>
      <div class="frame-modal-bottom">
        <div class="total">
          <span class="total-name">{{ $static('busked.total', 'Всього:') }}</span>
          <span class="total-value">{{ total }} <small>{{ $store.getters.getSettings.symbol }}</small></span>
        </div>
        <button class="default-btn trigger-to-order" @click="orderPage">
          <span class="btn-text">{{ $static('busked.order', 'Оформити') }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="thanks-modal" id="thx" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.title', 'Дякуємо за покупку!') }}</div>
        <p>{{ $static('thanks.subtitle', 'Переходимо до оплати...') }}</p>
      </div>
    </div>
  </div>


  <div class="thanks-modal" id="errorModal" style="background: rgba(0,0,0,0.5);">
    <div class="thanks-body">
      <div class="thanks-text">
        <div class="h2">{{ $static('thanks.error', 'Сталася помилка') }}</div>
        <p>{{ $static('thanks.subtitle_error', 'Будь ласка перевірте ваші данні і спробуйте ще.') }}</p>
      </div>
    </div>
  </div>

  <div class="frame-modal-wrapper-bg"></div>
  <div class="quick-card-sticker mobile-hidden" id="quick_card_sticker">
    <div class="quick-card-sticker-holder">
      <a href="javascript:void(0);">
					<span class="notification">
						{{ buskedLength }}
					</span>
        <img src="/images/icons/bag-outline-menu.svg">
        <span class="value">{{ total }} {{ $store.getters.getSettings.symbol }}</span>
      </a>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Busked',
  mounted() {
    (function ($) {
      'use strict';

      $('.quick_cart_trigger, #quick_card_sticker a').on('click', function () {
        $('body').addClass('no-scroll');
        $('#quick_cart').addClass('opened');
        $('.frame-modal-wrapper-bg').addClass('is-visible');
        return false;
      });

      $('.frame-modal-wrapper-bg').on('click', function () {
        $(this).removeClass('is-visible');
        $('.quick-card-sticker-li').removeClass('active');
        $('.overlay-menu').removeClass('active');
        $('.show-menu-btn').removeClass('open');
        $('body').removeClass('no-scroll');

        $('#quick_cart').removeClass('opened');
        return false;
      });

      $('.frame-modal-wrapper .close-cart').on('click', function () {
        $('.frame-modal-wrapper').removeClass('opened');
        $('.quick-card-sticker-li').removeClass('active');
        $('body').removeClass('no-scroll');
        $('.frame-modal-wrapper-bg').removeClass('is-visible');
        return false;
      });

      $('.frame-back-link .back-link').on('click', function () {
        $('.frame-modal-wrapper').removeClass('opened');
        $('body').removeClass('no-scroll');
        $('.frame-modal-wrapper-bg').removeClass('is-visible');
        return false;
      });

      $(function () {
        if ($(window).scrollTop() >= 100) {
          $('#quick_card_sticker').addClass('stickytop');
        }
        $(window).scroll(function () {
          if ($(this).scrollTop() >= 100) {
            $('#quick_card_sticker').addClass('stickytop');
          } else {
            $('#quick_card_sticker').removeClass('stickytop');
          }
        });

      });
    })(window.$);
  },
  beforeUnmount() {
    (function ($) {
      'use strict';
      $('.frame-modal-wrapper').removeClass('opened');
      $('body').removeClass('no-scroll');
      $('.frame-modal-wrapper-bg').removeClass('is-visible');

    })(window.$);
  },
  methods : {
    orderPage() {
      this.$router.push({name: 'Order'});
    },

    price(item) {
      return window.selectedPrice(item)
    },
    itemPrice(item) {
      return window.itemPrice(item)
    },
    buskedSelect(index) {
      this.$store.commit('setSelected', index)
    },
    delBasked(index) {
      this.$store.commit('delBasked', index)
    },
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    }
  },
  computed: {
    busked() {
      return this.$store.getters.getBusked
    },
    selected(){
      return this.$store.getters.getSelected
    },
    buskedSelected() {
      return this.$store.getters.getBuskedSelected
    },
    total() {
      return this.$store.getters.getBuskedTotal
    },
    buskedLength(){
      return this.busked.reduce((prev,x) => prev + x.length,0)
    },
  }
}
</script>

<style scoped>

</style>