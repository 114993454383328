<template>
  <div class="swiper-wrapper">
    <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
      <div class="carousel-item-body">
        <div class="product-label-holder">
          <div class="product-label label-with-icon label-important" v-if="slide.chief">
            <img src="/images/icons/cheaf-hat.svg">
            <span class="label-text">Від шеф-кухаря</span>
          </div>
          <div class="product-label label-new" v-if="slide.new">
            <span class="label-text">New</span>
          </div>
        </div>
        <div class="text">
          <div class="name h2">{{ $dynamic(slide, slide.title, 'title') }}</div>
          <p class="caption">{{ $dynamic(slide, slide.subtitle, 'subtitle') }}</p>
          <span class="price-element">{{ $dynamic(slide, slide.price, 'price') }}</span>
          <a href="javascript:void(0)" class="item-link" @click="goToSlide(slide)">
            Детальніше
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                  fill="white"/>
            </svg>
          </a>
        </div>
        <div class="image bg-cover" :style="{'background-image': $filters.backgroundImage(slide.url)}"></div>
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</template>

<script>
export default {
  name : 'HomeSlides',
  props: ['slides'],
  data() {
    return {
      swiper: undefined
    }
  },
  methods: {
    goToSlide(slide) {
      // console.log(slide)
      if (!slide.link.startsWith('https')){
        this.$router.push(slide.link)
        return
      }
      let path = new URL(decodeURI(slide.link))
      // console.log(path)
      let goTo = `${path.pathname}`
      if (path.search) {
        goTo += `?${path.search}`
      }
      // console.log(goTo)
      this.$router.push(goTo)
    }
  },
  mounted() {
    if (!this.swiper) {
      // this.slides = document.querySelectorAll('.swiper-slide');

      this.swiper = new window.Swiper('.main-gallery', {
        //effect: 'fade',
        slidesPerView: 1,
        loop         : true,
        spaceBetween : 15,
        speed        : 700,
        autoplay     : {
          delay               : 3000,
          disableOnInteraction: false
        },
        pagination   : {
          el            : '.swiper-pagination',
          dynamicBullets: false
        }
      });
    }
  }
}
</script>