import { createStore } from 'vuex'

let saveBusked = (item) => {
  window.localStorage.setItem('_BUSKED', JSON.stringify(item))
};

let busked = [];
(() => {
  let StartBUSKED = window.localStorage.getItem('_BUSKED');
  if (StartBUSKED) {
    busked = JSON.parse(StartBUSKED)
    if (busked.length > 0) {
      if (busked.some(i => {return i.additionSelect})) {
        busked = []
      }
      
    }
  }
  window.busked = busked
})();

window.addition_price  = function (item) {
  return item.additionSelect ? item.additionSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
}
window.addition_Uprice = function (item) {
  return item.additionUSelect ? item.additionUSelect.reduce((c, value) => value.price ? c + value.price : c, 0) : 0
}
window.selectedPrice   = function (item) {
  if (item.selectedMod > 0) {
    return item?.advanced?.modList[item.selectedMod].price ? item.advanced.modList[item.selectedMod].price : 0
  }
  return item.price
}
window.itemPrice       = function (item) {
  return (window.selectedPrice(item) * item.count) + window.addition_price(item) * item.count + window.addition_Uprice(item) * item.count
}

export default createStore(
  {
    state    : {
      iswebapp       : false,
      userInfo       : undefined,
      account        : undefined,
      categories     : [],
      settings       : [],
      tags           : [],
      options        : {},
      optionsSettings: {},
      addition       : [],
      busked         : busked,
      selectedBusked : 0,
      storehouse     : [],
      defaultDelivery: undefined,
      defaultPayment : undefined,
      delivery       : [],
      payment        : [],
      landing        : undefined,
      position       : undefined
    },
    getters  : {
      isWebApp          : (state) => state.iswebapp,
      getCategories     : (state) => state.categories,
      getSettings       : (state) => state.settings,
      getOptions        : (state) => state.options,
      getOptionSettings : (state) => state.optionsSettings,
      getTags           : (state) => state.tags.sort((a, b) => a.order - b.order),
      getAddition       : (state) => state.addition,
      getBusked         : (state) => state.busked,
      getBuskedSelected : (state) => state.busked[state.selectedBusked] ? state.busked[state.selectedBusked] : [],
      getSelected       : (state) => state.selectedBusked,
      getBuskedTotal    : (state) => state.busked.reduce((c, order) => c + order
        .map(item => window.itemPrice(item))
        .reduce((a, b) => a + b, 0), 0),
      getStorehouse     : (state) => state.storehouse,
      getDefaultDelivery: (state) => state.defaultDelivery,
      getDefaultPayment : (state) => state.defaultPayment,
      getPayment        : (state) => state.payment,
      getDelivery       : (state) => state.delivery,
      getUserInfo       : (state) => state.userInfo,
      getAccount        : (state) => state.account,
      landing           : (state) => state.landing,
      getPosition       : (state) => state.position
    },
    mutations: {
      setIsWebApp       : (state, payload) => state.iswebapp = payload,
      setCategories     : (state, payload) => state.categories = payload,
      setSettings       : (state, payload) => state.settings = payload,
      setTags           : (state, payload) => state.tags = payload,
      setPosition       : (state, payload) => state.position = payload,
      setAddition       : (state, payload) => state.addition = payload,
      setSelected       : (state, payload) => state.selectedBusked = payload,
      setOptions        : (state, payload) => {
        state.options[payload.option_id] = payload
      },
      setOptionSettings : (state, payload) => {
        state.optionsSettings = payload
      },
      setStorehouse     : (state, payload) => state.storehouse = payload,
      setDefaultPayment : (state, payload) => state.defaultPayment = payload,
      setDefaultDelivery: (state, payload) => state.defaultDelivery = payload,
      setPayment        : (state, payload) => state.payment = payload,
      setDelivery       : (state, payload) => state.delivery = payload,
      setLanding        : (state, payload) => state.landing = payload,
      setUserInfo       : (state, payload) => state.userInfo = payload,
      setAccount        : (state, payload) => state.account = payload,
      
      addToBasked       : (state, payload) => {
        if (!Array.isArray(state.busked[state.selectedBusked])) {
          state.busked[state.selectedBusked] = []
        }
        state.busked[state.selectedBusked].push(payload);
        saveBusked(state.busked);
      },
      replaceBuskedIndex: (state, payload) => {
        if (payload.index > -1 && state.busked[state.selectedBusked].length > payload.index) {
          state.busked[state.selectedBusked][payload.index] = payload.value
        }
        saveBusked(state.busked);
      },
      secondBusked      : (state, payload) => {
        if (state.busked.length < 2) {
          state.busked.push([])
        }
        let findIndex = state.busked[0].findIndex(x => x.id === payload)
        if (findIndex > -1) {
          state.busked[1].push(state.busked[0][findIndex])
          state.busked[0].splice(findIndex, 1);
        }
        saveBusked(state.busked);
      },
      
      delBasked  : (state, payload) => {
        state.busked[state.selectedBusked].splice(payload, 1);
        if (state.selectedBusked === 1 && state.busked[state.selectedBusked].length === 0) {
          state.selectedBusked = 0
          state.busked         = [state.busked[0]]
        }
        saveBusked(state.busked);
      },
      clearBasked: (state) => {
        state.busked = [];
        saveBusked(state.busked);
      }
    },
    actions  : {},
    modules  : {}
  });
