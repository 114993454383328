<template>
  <div class="tgme-wrapper">
    <div class="tgme-panel">
      <div class="logo" :style="{'background-image': $filters.backgroundImage(settings.logo)}"></div>
      <div class="top-info">
        <div class="username">@{{ settings.BOT_NAME }}</div>
      </div>

      <div class="content">
        <p v-if="settings.previewText" v-html="settings.previewText"></p>
        <div class="catalog-item">
          <div class="item-holder" v-if="item">
            <div class="video-container" v-if="videos?.length">
              <a href="javascript:void(0)" t></a>
              <video :src="videos[0].url" aria-hidden="true" playsinline muted autoplay loop
                     :type="videos[0].type" :poster="getItemImage(item)"></video>
            </div>
            <a v-else href="javascript:void(0)" class="image bg-cover"
               :style="{'background-image': $filters.backgroundImage(item.preview)}"></a>
            <div class="item-content">
              <div class="product-name">
                <a href="javascript:void(0)" t>
                  {{ item.title }}
                </a>
              </div>
            </div>
            <div class="price-box">
              <div class="price-old" v-if="item.old_price">
                {{ $filters.money(item.old_price) }} {{ $store.getters.getSettings.symbol }}
              </div>
              <div class="price-element">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
            </div>
            <div class="btns">
              <div class="tgme-btn">
                <a href="javascript:void(0)" t>{{ $static('tgme.open-tg', 'Відкрити у Телеграмі') }}</a>
              </div>
              <div class="default-link">
                <router-link :to="{name: 'Item', params:{category:'main', id: id}}">
                  {{ $static('tgme.no-tg', 'Немає телеграму') }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="item-holder" v-else>
            <div class="btns">
              <div class="tgme-btn">
                <a href="javascript:void(0)" t>
                  {{ $static('tgme.open-tg', 'Відкрити у Телеграмі') }}
                </a>
              </div>
              <div class="default-link">
                <router-link :to="{name: 'home'}">
                  {{ $static('tgme.no-tg', 'Немає телеграму') }}
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tgme-bg" style="background-image:url('/images/tg-pattern.png');"></div>
    <div class="gradient"></div>
  </div>
</template>

<script>
export default {
  name : 'Subscribe',
  props: ['id'],
  data() {
    return {
      item: undefined
    }
  },
  computed: {
    settings() {
      return this.$store.getters.getSettings
    },
    videos() {
      return this.item?.advanced?.videos?.length ? this.item.advanced.videos : undefined
    }
  },
  methods : {
    fetchSettings() {
      return fetch('/public/settings').then(response => response.json()).then(
          data => {
            if (data) {
              this.$store.commit('setSettings', data);
            }
          })
    },
    fetchItem(id) {
      return fetch('/public/item/' + id).then(response => response.json()).then(
          data => {
            if (data && data.result) {
              this.item = data.result;
            }
          })
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    }
  },
  mounted() {
    fetch('/public/save', {method:"POST", body: JSON.stringify({href: window.location.href})});

    const searchParams = new URLSearchParams(window.location.search);
    let key            = undefined;
    if (searchParams.has('utm_key')) {
      key = searchParams.get('utm_key');
    }

    let settings  = this.fetchSettings();
    let hex       = parseInt(this.id, 10).toString(16);
    let awaitList = [settings];

    if (this.id) {
      awaitList.push(this.fetchItem(this.id));
    }
    Promise.all(awaitList).then(() => {
      window.$('.preloader-page').removeClass('proccess-load');

      let addLink = '';
      let dLink   = '/';
      if (this.settings.DIRECT_LINK) {
        dLink = this.settings.DIRECT_LINK;
      }
      let directLink = dLink.split('/');
      let startApp   = [];
      if (directLink.length === 5) {
        let bot_name = directLink[3];
        let app_name = directLink[4];
        addLink      = `${bot_name}&appname=${app_name}`;

        if (this.id) {
          startApp.push(`t${hex}`)
        }
        if (key) {
          startApp.push(key)
        }
        // if (fbc){
        //   addLink += `_fbc${fbc}`
        // }
        if (searchParams.has('fbclid')) {
          startApp.push('fbclid' + searchParams.get('fbclid'));
          // addLink += '_fbclid'+searchParams.get('fbclid');
          // query = query + '?fbclid=' + searchParams.get('fbclid');
        }

        if (startApp.length) {
          addLink += `&startapp=` + startApp.join('_')
        }
      } else {
        addLink = this.settings.BOT_NAME;
      }

      Array.from(document.querySelectorAll('a[t]')).forEach(i => {
        i.href = 'tg://resolve?domain=' + addLink;
      });
      Array.from(document.querySelectorAll('a[t]'))[0].click();
    })
  },
  watch: {
    settings(val) {
      if (val && val.favicon) {
        let link = document.querySelector('link[rel~=\'icon\']');
        if (link) {
          link.href = val.favicon
        }
      }
    }
  }
}
</script>

<style scoped>
.tgme-wrapper {
  position   : relative;
  height     : 100vh;
  min-height : 600px;
  padding    : 30px 15px;
}

.tgme-wrapper .catalog-item {
  width  : 100%;
  margin : 0;
}

.tgme-wrapper .catalog-item .image {
  height : 310px;
}

.tgme-wrapper .catalog-item .btns {
  border-top  : 1px solid #dfe0e2;
  padding-top : 15px;
}

.tgme-wrapper .catalog-item .btns .default-link a {
  color       : #a3a3a3;
  margin-top  : 15px;
  font-weight : 500;
  display     : block;
}

.tgme-wrapper .catalog-item .price-box {
  margin-bottom           : 15px;
  display                 : -webkit-box;
  display                 : -moz-box;
  display                 : -ms-flexbox;
  display                 : -webkit-flex;
  display                 : flex;
  justify-content         : center;
  -moz-justify-content    : center;
  -webkit-justify-content : center;
}

.tgme-wrapper .catalog-item .price-box .price-old {
  margin-right : 10px;
}

.tgme-wrapper .catalog-item .product-name {
  min-height         : auto;
  margin-bottom      : 10px;
  -webkit-line-clamp : inherit;
}

.tgme-wrapper .tgme-panel {
  z-index          : 10;
  width            : 100%;
  max-width        : 345px;
  background-color : #ffffff;
  border-radius    : 9px;
  padding          : 30px 15px;
  position         : relative;
  text-align       : center;
  box-shadow       : 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  font-size        : 14px;
  line-height      : 21px;
  margin           : auto;
}

.tgme-wrapper .tgme-panel ul {
  width  : 70%;
  margin : 0 auto 10px;
}

.tgme-wrapper .tgme-panel ul li {
  line-height   : 1.2;
  margin-bottom : 4px;
  position      : relative;
}

.tgme-wrapper .tgme-panel ul li span {
  display  : inline;
  position : relative;
}

.tgme-wrapper .tgme-panel ul li span:before {
  content          : "";
  display          : block;
  width            : 5px;
  height           : 5px;
  border-radius    : 50%;
  position         : absolute;
  left             : -9px;
  top              : 6px;
  background-color : #212121;
}

.tgme-wrapper .tgme-panel .tgme-btn {
  text-align : center;
}

.tgme-wrapper .tgme-panel .tgme-btn a {
  display          : inline-block;
  text-transform   : uppercase;
  color            : #ffffff;
  border-radius    : 30px;
  line-height      : 36px;
  background-color : #2481CC;
  text-decoration  : none;
  padding          : 0 17px;
}

.tgme-wrapper .tgme-panel .tgme-btn a:hover {
  color            : #ffffff;
  background-color : #2073b6;
}

.tgme-wrapper .tgme-panel .content p {
  margin-bottom : 10px;
  font-size     : 14px;
  line-height   : 21px;
}

.tgme-wrapper .tgme-panel .top-info {
  margin-bottom : 15px;
}

.tgme-wrapper .tgme-panel .top-info .username {
  color     : #7D7F81;
  font-size : 16px;
  opacity   : 0.6;
}

.tgme-wrapper .tgme-panel .top-info .name {
  font-weight : 500;
  font-size   : 24px;
  line-height : 27px;
}

.tgme-wrapper .tgme-panel .logo {
  margin              : 0 auto 10px;
  width               : 110px;
  height              : 110px;
  background-color    : #f3f3f3;
  background-position : center center;
  background-repeat   : no-repeat;
  border-radius       : 50%;
}

.tgme-wrapper .tgme-bg {
  position : absolute;
  left     : 0;
  top      : 0;
  width    : 100%;
  height   : 100%;
  z-index  : 1;
}

.tgme-wrapper .gradient {
  position   : absolute;
  left       : 0;
  top        : 0;
  width      : 100%;
  height     : 100%;
  background : linear-gradient(164deg, #a1c680 6.69%, #98c184 21.02%, #b7cf96 33.1%, #bdcfa5 52.79%, #a3c5a2 73.82%);
  background : -webkit-linear-gradient(164deg, #a1c680 6.69%, #98c184 21.02%, #b7cf96 33.1%, #bdcfa5 52.79%, #a3c5a2 73.82%);
}

.logo {
  background-size : contain;
}

.video-container {
  text-align : center;
  position   : relative;
}

.video-container a {
  position : absolute;
  width    : 100%;
  height   : 100%;
  z-index  : 111;
}

.tgme-wrapper {
  min-height : 100vh;
  height     : auto;
}

.video-container video {
  max-width  : 100%;
  max-height : 100%;
  z-index    : 11;
  width      : 100%;
}
</style>