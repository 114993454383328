<template>
  <div class="order-info order-attention panel-bordered" v-if="unavailable.length">
    <h3>{{ $static('order.info-attention', 'Нема в наявності в цьому відділенні:') }}</h3>
    <div v-for="(item,index) in unavailable" :key="index">
      <div class="cart-item">
        <div class="item-holder">
          <a class="remove">
            <img src="/images/icons/delete.svg" alt="" @click.prevent="delBasked(item)">
          </a>
          <div class="item-info">
            <div class="image bg-cover" :style="{'background-image': $filters.backgroundImage(item.preview)}">

            </div>
            <div class="right-side">
              <!--              <div class="name">{{item.title}}</div>-->
              <router-link :to="{name: 'Item', params:{ category:  categoryURL(item), id: item.id}}">
                {{ item.title }}
              </router-link>
              <div class="info-labels">
                <span class="info-label">
                  {{ $static('order.count', 'Кількість:') }}
                  <span>{{ item.count }} {{ $static('busked.nam', 'шт') }}</span>
                </span>
              </div>

              <div class="order-adds-box">
                <div class="product-price">
                  <div class="additional-line" v-if="item.price">
                    <div class="add-name">{{ $static('order.price', 'Ціна') }}</div>
                    <div class="value">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
                  </div>
                </div>
                <div class="additional" v-if="item.additionSelect">
                  <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                    <div class="add-name">{{ m.name }}</div>
                    <div class="value" v-if="m.price">{{ m.price }} {{ $store.getters.getSettings.symbol }}</div>
                  </div>
                </div>
                <div class="additional" v-if="item.additionUSelect">
                  <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                    <div class="add-name">{{ m.name }}</div>
                    <div class="value" v-if="m.price">{{ m.price }} {{ m.old_price }} {{ $store.getters.getSettings.symbol }}</div>
                  </div>
                </div>
              </div>

              <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }} {{ $store.getters.getSettings.symbol }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="attention-bottom">
        <div class="title" v-if="item.stop?.text">
          <span class="red">Зверніть увагу!</span><br/>
          {{ item.stop.text }}
        </div>
        <div class="title" v-if="item.stop?.replaced">
          <span class="red">Зверніть увагу!</span><br/>
          <!-- Сюда сверстать товар замены -->
          {{ item.stop.replaced }}
          <div class="cart-item">
            <div class="item-holder">
              <div class="item-info">
                <div class="image bg-cover" :style="{'background-image': $filters.backgroundImage(item.preview)}">

                </div>
                <div class="right-side">
                  <div class="name">{{ item.title }}</div>
                  <div class="order-adds-box">
                    <div class="product-price">
                      <div class="additional-line" v-if="item.price">
                        <div class="add-name">{{ $static('order.price', 'Ціна') }}</div>
                        <div class="value">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }} {{ $store.getters.getSettings.symbol }}</div>-->

                </div>
              </div>
            </div>
          </div>
        </div>

<!--        <div class="attention-actions d-flex align-items-center justify-content-between">-->
<!--          <button class="default-btn btn-no-icon green-btn" v-if="item.stop?.text || item.stop?.replaced">-->
<!--            <span class="btn-text">Підтвердити</span>-->
<!--          </button>-->
<!--          <a href="javascript:void(0)" v-if="selected === 0" class="default-link" @click="secondBusked(item.id)">-->
<!--            <span class="text">Замовити в іншому відділенні</span>-->
<!--            <img src="/images/icons/right.svg">-->
<!--          </a>-->
<!--        </div>-->
      </div>
    </div>
  </div>

  <div class="order-info panel" v-if="available.length">
    <h3>{{ $static('order.info', 'Ваше замовлення') }}</h3>
    <div class="cart-item ">
      <div class="item-holder" v-for="(item,index) in available" :key="index">
        <a class="remove">
          <img src="/images/icons/delete.svg" alt="" @click.prevent="delBasked(item)">
        </a>
        <div class="item-info">
          <div class="image bg-cover" :style="{'background-image': $filters.backgroundImage(item.preview)}">

          </div>
          <div class="right-side">
            <!--              <div class="name">{{item.title}}</div>-->
            <router-link :to="{name: 'Item', params:{category: categoryURL, id: item.id}}">
              {{ item.title }}
            </router-link>
            <div class="info-labels">
                <span class="info-label">{{ $static('order.count', 'Кількість:') }}
                  <span>{{ item.count }} {{ $static('busked.nam', 'шт') }}</span>
                </span>
            </div>

            <div class="order-adds-box">
              <div class="product-price">
                <div class="additional-line" v-if="item.price">
                  <div class="add-name">{{ $static('order.price', 'Ціна') }}</div>
                  <div class="value">{{ $filters.money(price(item)) }} {{ $store.getters.getSettings.symbol }}</div>
                </div>
              </div>
              <div class="additional" v-if="item.additionSelect">
                <div class="additional-line" v-for="(m, index) in item.additionSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <div class="value" v-if="m.price">{{ m.price }} {{ $store.getters.getSettings.symbol }}</div>
                </div>
              </div>
              <div class="additional" v-if="item.additionUSelect">
                <div class="additional-line" v-for="(m, index) in item.additionUSelect" :key="index">
                  <div class="add-name">{{ m.name }}</div>
                  <div class="value" v-if="m.price">{{ m.price }} {{ m.old_price }} {{ $store.getters.getSettings.symbol }}</div>
                </div>
              </div>
            </div>

            <div class="subtotal">{{ item.count }} x {{ $filters.money(itemPrice(item)) }} {{ $store.getters.getSettings.symbol }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name    : 'OrderInfo',
  props   : ['busked', 'order'],
  computed: {
    available() {
      return this.busked ? this.busked.filter(i => !i.stop) : []
    },
    unavailable() {
      return this.busked ? this.busked.filter(i => i.stop) : []
    },
    selected() {
      return this.$store.getters.getSelected
    }
  },
  methods : {
    categoryURL(item) {
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    delBasked(item) {
      let x = this.busked.findIndex(i => i.id === item.id)
      if (x > -1) {
        this.$store.commit('delBasked', x)
      }
    },
    itemPrice(item) {
      return window.itemPrice(item)
    },
    secondBusked(id) {
      return this.$store.commit('secondBusked', id)
    },
    price(item) {
      return window.selectedPrice(item)
    }

  }
}
</script>