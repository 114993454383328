<template>
  <div class="map-holder">
    <div id="map-show" :style="{'min-height': mapHeight}"></div>
    <template v-if="!noButton">
      <div class="map-buttons" v-if="open">
        <button class="default-btn square-btn" @click="cancel">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5L5 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 5L15 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <div class="map-buttons" v-else>
        <button class="default-btn square-btn" @click="openMap">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M17.879 4.51313C18.0392 4.75572 18.0125 5.0854 17.799 5.29896L10.1386 12.9593C10.0601 13.0378 9.96208 13.094 9.85464 13.1221L6.66429 13.9554C6.56023 13.9826 6.45297 13.982 6.3522 13.9564C6.24506 13.9291 6.14525 13.8735 6.0644 13.7926C5.90751 13.6357 5.84556 13.4074 5.90163 13.1927L6.73496 10.0024C6.75952 9.90836 6.8056 9.81551 6.86934 9.74268L14.558 2.05806C14.6254 1.99076 14.7064 1.94038 14.7947 1.90969C14.8601 1.88694 14.9294 1.875 15 1.875C15.1657 1.875 15.3247 1.94085 15.4419 2.05806L17.799 4.41508C17.8295 4.44559 17.8562 4.47847 17.879 4.51313ZM16.4731 4.85702L15 3.38388L7.90152 10.4824L7.3807 12.4763L9.37467 11.9555L16.4731 4.85702Z"
                  fill="black"/>
            <path
                d="M16.3678 14.3003C16.5956 12.3522 16.6681 10.3906 16.5853 8.43399C16.5813 8.34031 16.6165 8.24915 16.6828 8.18285L17.5023 7.36341C17.603 7.26266 17.7752 7.32662 17.7846 7.46878C17.9387 9.79349 17.8803 12.1288 17.6093 14.4455C17.4122 16.1309 16.0586 17.4518 14.3819 17.6391C11.4931 17.962 8.50694 17.962 5.61813 17.6391C3.94142 17.4518 2.58781 16.1309 2.39068 14.4455C2.04523 11.4919 2.04523 8.50811 2.39068 5.55452C2.58781 3.86908 3.94142 2.54824 5.61813 2.36084C7.80959 2.11592 10.057 2.05679 12.2755 2.18347C12.4185 2.19163 12.4837 2.36465 12.3824 2.46591L11.5553 3.29304C11.4897 3.35862 11.3998 3.39384 11.3071 3.39071C9.45175 3.32814 7.58378 3.39894 5.75697 3.60311C4.64855 3.72699 3.76064 4.60167 3.63221 5.69973C3.29805 8.55684 3.29805 11.4432 3.63221 14.3003C3.76064 15.3983 4.64855 16.273 5.75697 16.3969C8.55351 16.7094 11.4465 16.7094 14.243 16.3969C15.3515 16.273 16.2394 15.3983 16.3678 14.3003Z"
                fill="black"/>
          </svg>
        </button>
      </div>
    </template>
  </div>
</template>

<style scoped>
.map-holder {
  position : relative;
}
</style>

<script>
export default {
  name : 'Map',
  props: {
    point      : {
      type   : Object,
      default: () => {
        return undefined
      }
    },
    storehouses: {
      type   : Array,
      default: () => {
        return []
      }
    },
    noButton: {
      type   : Boolean,
      default: () => {
        return false
      }
    },
  },
  data() {
    return {
      map   : undefined,
      mCheck: undefined,
      open  : false,
      timer : undefined
    }
  },
  computed: {
    mapHeight() {
      return this.open ? '60vh' : '200px'
    },
    myPoint() {
      if (this.point) {return this.point}
      if (this.storehouses?.length && this.storehouses[0].info?.point) {
        return this.storehouses[0].info.point
      }
      let pos   = this.$store.getters.getPosition
      let point = undefined
      if (pos && pos.loc) {
        let [lat, lng] = pos.loc.split(',')
        if (lat && lng) {
          point = {lat: lat, lng: lng, zoom: 10, zone: []}
        }
      }
      return point
    }
  },
  emits   : ['update:modelValue'],
  mounted() {
    this.mCheck = document.querySelector('#map-show');
    console.log('make map point', this.myPoint )
    this.makeMap(this.myPoint)
    this.closeMap()
    if (this.noButton){
      this.open = true
    }
    this.timer = setTimeout(() => {
      this.map.invalidateSize()
    }, 100)
  },
  unmounted() {
    if (this.map) {
      this.map.off();
      this.map.remove();
      this.map = undefined;
    }
  },
  watch  : {
    point(val) {
      if (!this.modelValue && val) {
        this.makeMap(val)
      }
    },
    open() {
      this.timer = setTimeout(() => {
        this.map.invalidateSize()
      }, 30);
    }
  },
  methods: {
    openMap() {
      this.open = true
      this.map.dragging.enable();
      this.map.scrollWheelZoom.enable();
    },
    closeMap() {
      this.open = false
      this.map.dragging.disable();
      this.map.scrollWheelZoom.disable();
    },
    cancel() {
      this.closeMap();
      if (this.saved) {
        setTimeout(() => {
          this.map.setView([this.saved.lat, this.saved.lng])
        }, 40)
      }
    },
    makeMap(point) {
      if (this.map) {
        this.map.off();
        this.map.remove();
      }
      this.map = window.L.map('map-show').setView([point.lat, point.lng], point.zoom)
      if (this.noButton){
        window.L.marker(point, {draggable: false}).addTo(this.map);
      }

      if (this.storehouses?.length) {
        this.storehouses.forEach(store => {
          if (store?.info?.point) {
            if (store?.info?.icon?.length) {
              let storePoint = window.L.icon({
                                               iconUrl  : store.info.icon[0],
                                               className: 'storehouse-marker',
                                               iconSize : [40, 40] // size of the icon
                                             });
              window.L.marker(store?.info?.point, {draggable: false, icon: storePoint})
                  .bindPopup(store.name)
                  .addTo(this.map);
            } else {
              window.L.marker(store?.info?.point, {draggable: false})
                  .bindPopup(store.name)
                  .addTo(this.map);
            }
          }
        })
      }
      window.L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);
    }
  }
}
</script>

<style>
.storehouse-marker {
  border-radius : 50%;
  border        : 3px solid #000000;
}

.placeholder {
  cursor        : pointer;
  line-height   : 40px;
  color         : #888888 !important;
  overflow      : hidden;
  text-wrap     : nowrap;
  text-overflow : ellipsis;
}
</style>