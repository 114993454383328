<template>
  <div v-html="html" class="default-wrapper wrapper"></div>
</template>

<script>
  export default {
    name: 'Dostup',
    data() {return {html: '', lang: ''}},
    computed: {
      isUpdate() {
        return this.$updateLang()
      }
    },
    watch   : {
      isUpdate() {
        this.getPage()
      }
    },
    methods : {
      getPage() {
        let query = '';
        let lang  = localStorage.getItem('$lang');
        if (this.lang !== lang) {
          if (lang) {
            query = '?lang=' + lang
          }
          fetch('/public/template/dostup' + query).then(response => response.json()).then(data => {
            let name        = 'Dostupnist'
            let settings    = data && data.settings ? data.settings : {}
            let title       = settings.meta_title ? settings.meta_title : name;
            let description = settings.meta_description ? settings.meta_description : '';
            let image       = settings.image ? `${window.location.protocol}//${window.location.host}${settings.image}` : '';

            document.querySelector('title').innerHTML = title;
            document.querySelector('meta[property="og:title"]').setAttribute('content', title);
            document.querySelector('meta[name="description"]').setAttribute('content', description);
            document.querySelector('meta[property="og:description"]').setAttribute('content', description);
            document.querySelector('meta[property="og:image"]').setAttribute('content', image);

            this.html = data.template;
          })
        }
        this.lang = lang;
      }
    },
    mounted() {
      this.getPage()
    }
  }
</script>

<style scoped>

</style>