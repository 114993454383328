import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugin/i18n'
import Analytic from './plugin/analytic'
import 'bootstrap';

const formatter                      = new Intl.NumberFormat();
const translate                      = new i18n({store});
const analytic                       = new Analytic();
const app                            = createApp(App);
app.config.globalProperties.$filters = {
  money(value) {
    if (typeof value !== 'number') {
      return value;
    }
    return formatter.format(value);
  },
  goBack(){
    if (window.history.state.back) {
      router.back()
    }else {
      window.Telegram.WebApp.close()
    }
  },
  backgroundImage(image) {
    if (!image) {return image}
    if (image?.url){
      if (image.url.includes(".mp4")) {
        return 'url(images/mp4.png)';
      }
      if (image.url.includes(".webm")) {
        return 'url(images/webm.png)';
      }
      if (image.url.includes(".ogg")) {
        return 'url(images/ogg.png)';
      }
    }
    if (image.includes('https://')) {
      return 'url("' + image + '")'
    }
    return image ? 'url("' + `${window.location.protocol}//${window.location.host}${image}` + '")' : ''
  }
};

app.use(translate).use(analytic).use(store).use(router).mount('#app');
