<template>
  <header>
    <div class="header-holder">
      <div class="menu-button">
        <a href="javascript:void(0);" class="show-menu-btn">
          <span></span>
          <span></span>
          <span></span>
        </a>
        <SearchItem/>
      </div>
      <div class="header-info" v-if="s_info" v-html="s_info">

      </div>
      <div class="logo-holder">
        <router-link :to="{name: 'home'}">
          <img :src="logo">
        </router-link>
      </div>

      <!--      <div class="header-info right-header-info" v-if="phones?.length">-->
      <!--        <template v-for="(value,key) in phones" :key="key">-->
      <!--          <a :href="'tel:'+value.real" v-html="value.text" v-if="value.isNumber"></a>-->
      <!--          <p v-else v-html="value.text">-->
      <!--          </p>-->
      <!--        </template>-->
      <!--      </div>-->
      <div class="header-btns" v-if="userInfo">
        <a v-if="settings.consultant" :href="settings.consultant" class="chat-btn">
          <span class="text">Зв’язок з менеджером</span>
          <img src="/images/icons/telegram.svg" alt="Зв’язок з менеджером">
        </a>
        <div class="line desktop-hidden"></div>
        <a href="javascript:void(0)" @click="sendWebLink" class="default-btn btn-outline-light review-btn desktop-hidden">
          <img src="/images/icons/web.svg" alt="Web Версія">
          <span class="text">Web Версія</span>
        </a>
      </div>
      <div class="header-btns" v-else-if="BOT_NAME && !isWebApp">
        <div class="login-btn-holder">
          <telegram-login-temp
              mode="callback"
              :size="getSize"
              requestAccess="write"
              :telegram-login="BOT_NAME"
              @loaded='telegramLoadedCallbackFunc'
              @callback="yourCallbackFunction"
          />
        </div>
        <!--        <div class="line"></div>-->
        <!--        <a href="#" class="default-btn btn-outline-light review-btn">-->
        <!--          <img src="/images/icons/reviews.svg" alt="Відгуки">-->
        <!--          <span class="text">Відгуки</span>-->
        <!--        </a>-->
      </div>


      <!--      <div class="lang-panel">-->
      <!--        <div class="dropdown show" v-if="lang?.length">-->
      <!--          <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">-->
      <!--            <img :src="current.icon" alt="">-->
      <!--            {{ current.short }}-->
      <!--          </a>-->
      <!--          <div class="dropdown-menu" x-placement="bottom-end">-->
      <!--            <a v-for="(l,index) in lang" :key="index" class="dropdown-item active "-->
      <!--               href="javascript:void(0)" @click="setLang(l)"-->
      <!--               :class="{'active': l.text === current.text}">-->
      <!--              <img :src="l.icon" alt="">-->
      <!--              {{ l.short }}-->
      <!--            </a>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div v-else>-->
      <!--          <a href="javascript:void(0)">-->
      <!--            <img :src="current.icon" alt="">-->
      <!--            {{ current.short }}-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <Teleport to="body">
      <div class="overlay-menu">
        <div class="overlay-top">
          <router-link :to="{name:'home'}">
            <div class="logo">
              <img :src="logo">
            </div>
          </router-link>
          <a class="close-menu-btn" href="javascript:void(0);">
            <img src="/images/icons/close.svg">
          </a>
        </div>
        <div class="overlay-menu-wrapper">
          <div class="menu-list">
            <ul>
              <li>
                <router-link :to="{name:'home'}" :exact-active-class="'active'">
                  <span class="text">{{ $static('menu.home', 'Головна') }}</span>
                </router-link>
              </li>
              <li class="categories-menu" v-if="categories?.length">
                <span class="title-categories-menu">Меню</span>
                <ul class="categories-list">
                  <li v-for="(category, index) in categories" :key="index">
                    <router-link :to="'/catalog/'+category.info.url" :activeClass="'active'">
                      <span class="text">{{ $dynamic(category, category.name, 'name') }}</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link :to="{name:'about'}">
                  <span class="text">{{ $static('menu.about', 'Про нас') }}</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{name:'delivery'}">
                  <span class="text">{{ $static('menu.delivery', 'Доставка та оплата') }}</span>
                </router-link>
              </li>
              <li>
                <a href="#">
                  <span class="text">{{ $static('menu.contacts', 'Контакти') }}</span>
                </a>
              </li>
              <li>
                <router-link :to="{name:'CabinetHistory'}">
                  <span class="text">{{ $static('menu.cabinet', 'Кабінет') }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom-overlay-nav">
          <div class="chat-btn-holder">
            <a v-if="settings.consultan" :href="settings.consultan" class="chat-btn">
              <img src="/images/icons/telegram.svg" alt="Зв’язок з менеджером">
              <span class="text">Зв’язок з менеджером</span>
            </a>
          </div>
          <div class="menu-info-holder">
            <div class="menu-info time-info" v-if="s_info" v-html="s_info"></div>
          </div>
          <div class="overlay-lang-panel" v-if="lang?.length">
            <div class="lang-holder d-flex align-items-center">
              <div class="custom-label">Мова:</div>
              <div class="lang-links">
                <a v-for="(l,index) in lang" :key="index" href="javascript:void(0)"
                   @click="setLang(l)" :class="{'active': l.text === current.text}">
                  <img :src="l.icon" alt="">
                  {{ l.short }}
                </a>
                <!--              <a href="#" class="active">Ua</a>-->
                <!--              <a href="#">En</a>-->
                <!--              <a href="#">Язык</a>-->
              </div>
            </div>
          </div>
          <!--        <div class="soc-link">-->
          <!--          <a v-if="settings.instagram" :href="settings.instagram"># instagram</a>-->
          <!--        </div>-->
          <!--        <div class="develop-by" v-if="settings && settings.watter_mark">-->
          <!--          <a href="#">-->
          <!--            <div class="name"><img src="/images/developedby-dark.svg" alt="uxlove studio" class="developedby"></div>-->
          <!--            <img src="/images/uxlove-logo-dark.svg" alt="uxlove studio"></a>-->
          <!--        </div>-->
        </div>
      </div>
      <div class="open-nav"></div>
    </Teleport>
  </header>

  <div class="personal_area-sidebar through-personal-sidebar"><!--if not logged in add class .not-logged-->
    <div class="sidebar-name h2 desktop-hidden">Особистий кабінет</div>
    <a href="javascript:void(0);" class="close-personal-sidebar desktop-hidden">
      <img src="/images/icons/close.svg" alt="">
    </a>
    <div class="personal-area-sidebar-holder" v-if="userInfo">
      <div class="logged-in">
        <div class="logged-in-body">
        <span class="user-avatar image bg-cover" v-if="userInfo.photo_url"
              :style="{'background-image': 'url('+ userInfo.photo_url +')'}"></span>
          <span class="user-avatar" v-else-if="userInfo.first_name">{{ userInfo.first_name.substring(0, 1) }}</span>
          <span class="user-avatar image bg-cover" v-else-if="userInfo.username">{{ userInfo.username.substring(0, 1) }}</span>
          <div class="avatar-info">
            <div class="name">{{ userInfo.first_name }} {{ userInfo.last_name }}</div>
            <div class="bonus-panel" v-if="account">
              <span>Ваші бонуси: <span class="num">{{ account.bonus }}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="personal_area-menu">
        <ul>
          <li>
            <router-link :to="{name: 'CabinetHistory'}" :active-class="'active'">
								<span class="icons-holder">
                  <img src="/images/icons/history.svg" alt="">
                </span>
              <span class="text">Історія замовлень</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{name: 'CabinetInfo'}" :exact-active-class="'active'">
                <span class="icons-holder">
                  <img src="/images/icons/user.svg" alt="">
                </span>
              <span class="text">Особисті дані</span>
            </router-link>
          </li>
          <!--          <li>-->
          <!--            <router-link :to="{name: 'CabinetDelivery'}" :active-class="'active'">-->
          <!--									<span class="icons-holder">-->
          <!--                    <img src="/images/icons/loc.svg" alt="">-->
          <!--                  </span>-->
          <!--              <span class="text">Налаштування доставки</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
        </ul>
      </div>
      <div class="logout" v-if="!isWebApp">
        <a href="javascript:void(0)" @click="logout">
            <span class="icons-holder">
              <img src="/images/icons/logout.svg" alt="">
            </span>
          <span class="text">Вийти з аккаунту</span>
        </a>
      </div>
      <!--      <div class="button-holder">-->
      <!--        <button class="default-btn btn-no-icon">Увійти в кабінет</button>-->
      <!--      </div>-->
    </div>
    <div v-else>
      <p style="margin: 10px 15px;">Необхідна авторизація. Натисніть "Увійти як" - через телеграмм.</p>
    </div>
  </div>

  <Categories :categories="categories"></Categories>
</template>

<script>
import Categories from '@/components/Categories';
import SearchItem from '@/components/SearchItem';
import axios from 'axios';
import { telegramLoginTemp } from 'vue3-telegram-login';

export default {
  name      : 'MenuHeader',
  components: {SearchItem, Categories, 'telegram-login-temp': telegramLoginTemp},
  emits     : ['auth'],
  mounted() {},
  data() {
    return {
      init   : false,
      current: {
        icon : '/storage/i18n/ua.svg',
        title: 'Україньска',
        short: 'UA',
        value: '/storage/i18n/original.json'
      }
    }
  },
  computed: {
    userInfo() {
      return this.account?.telegram ? this.account?.telegram : undefined
    },
    isWebApp() {
      return this.$store.getters.isWebApp
    },

    account() {
      return this.$store.getters.getAccount
    },
    categories() {
      return this.$store.getters.getCategories.filter(i => !i.disabled).sort((a, b) => a.order - b.order)
    },
    settings() {
      return this.$store.getters.getSettings
    },
    BOT_NAME() {
      return this.settings.BOT_NAME ? this.settings.BOT_NAME : false
    },
    getSize() {
      return window.innerWidth < 500 ? 'small' : 'medium'
    },

    lang() {
      return this.settings ? this.settings.lang : this.settings
    },
    logo() {
      return this.settings && this.settings.logo ? this.settings.logo : ''
    },
    s_info() {
      return this.settings && this.settings.s_info ? this.settings.s_info : false
    },
    phones() {
      return this.settings && this.settings.phone ?
          this.settings.phone.split(',').map(x => x.trim())
              .map(x => {return {real: x.replace(/^\D+/g, ''), text: x, isNumber: x.startsWith('+')}}) : false
    }
  },
  methods : {
    logout() {
      localStorage.removeItem('user_info');
      this.$store.commit('setAccount', undefined);
      this.$store.commit('setUserInfo', undefined);
      this.$router.push({path: '/'});
      setTimeout(this.$router.go, 300);
    },
    telegramLoadedCallbackFunc() {
      // console.log('script is loaded')
      this.isLoaded = true;
    },
    sendWebLink() {
      // let user_info = JSON.parse(localStorage.getItem('user_info'));
      // if (user_info === null) {
      //   user_info = this.userInfo;
      // }
      axios.post('/public/get_my_link', {user_info: window.Telegram.WebApp.initData, ref: window.location.href})
          .then(data => {
            window.Telegram.WebApp.close();
          })
    },
    yourCallbackFunction(user) {
      console.log(user);
      localStorage.setItem('user_info', JSON.stringify(user));
      this.$store.commit('setUserInfo', user);
      this.$emit('auth', user);
      // fetch('/auth/login', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // })
      //   .then(response => response.json())
      //   .then(json => {
      //     localStorage.setItem('user_info', JSON.stringify(user));
      //     window.user_info = user;
      //     if (json.error === undefined) {
      //       if (json.init) {
      //         this.$router.push({name: 'Init'})
      //       } else {
      //         this.$router.push({name: 'Dashboard'})
      //       }
      //     }
      //   })
    },
    setLang(lang) {
      this.current = lang;
      this.fetchLang();
    },
    fetchLang() {
      fetch(this.current.value).then(response => response.json()).then(data => {
        if (data) {
          localStorage.setItem('$lang', this.current.short);
          this.$load(data, this.current.short)
        }
      })
    },
    fetchOrigin() {
      return fetch('/storage/i18n/original.json').then(response => response.json()).then(data => {
        if (data) {
          this.$load(data, this.current.short)
        }
      })
    }
  },
  watch   : {
    lang(val) {
      if (val) {
        this.fetchOrigin().then(() => {
          const lang = localStorage.getItem('$lang');
          if (lang) {
            let x = val.find(i => i.short === lang);
            if (x) {
              this.current = x;
              this.fetchLang()
            }
          }
        })
      }
    },
    settings(val) {
      if (val && val.favicon) {
        let link = document.querySelector('link[rel~=\'icon\']');
        if (link) {
          link.href = val.favicon
        }
      }
    }
  }
}
</script>