<template>
  <a href="javascript:void(0);" class="search-btn" @click="open = true">
    <img src="/images/icons/search.svg">
  </a>
  <teleport to="body">
    <div class="frame-modal-wrapper search-modal-wrapper" @click="open = false">
      <div class="modal-wrapper-holder" @click.stop.prevent="">
        <div class="frame-modal-header">
          <div class="name h3 no-uppercase">{{$static('search.title', 'Пошук продукту')}}</div>
          <a href="javascript:void(0);" class="close-cart" @click="open = false">
            <img src="/images/icons/close.svg">
          </a>
        </div>
        <div class="frame-modal-body">
          <div class="search-input">
            <img src="/images/icons/search.svg">
            <input type="text" id="query" class="form-control custom-input" ref="query" v-model="search" placeholder="...">
          </div>
          <div class="search-list">
            <ul>
              <li class="cart-item search-item" v-for="(item, index) in items" :key="index">
                <a href="javascript:void(0)" @click="openItem(item)">
                  <div class="item-holder">
                    <div class="item-info">
                      <div class="image bg" :style="{'background-image': $filters.backgroundImage(item.preview)}"></div>
                      <div class="right-side">
                        <div class="name">{{ $dynamic(item, item.title, 'title') }}</div>
                        <div class="option-box">
                          <div class="price-box">
                            <div class="price-element price-old" v-if="item.price_old">
                              {{ $filters.money(item.price_old) }} {{$store.getters.getSettings.symbol}}
                            </div>
                            <div class="price-element">{{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <div v-if="loading">loading</div>
              <div v-if="noResult">noresult</div>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </teleport>
</template>
<script>
export default {
  name: 'SearchItem',
  data() {
    return {
      open    : false,
      search  : '',
      loading : false,
      noResult: false,
      timer   : undefined,
      perPage : 20,
      items   : []
    }
  },
  methods: {
    openItem(item) {
      this.open = false
      this.$router.push({name: 'Item', params: {category: this.categoryURL(item), id: item.id}})
    },
    categoryURL(item) {
      // return {id: 1}
      // console.log(this.$store.getters.getCategories, item.category);
      let result = this.$store.getters.getCategories.find(i => i.id == item.category);
      if (!result) {
        return 'default'
      }
      return result.info.url
    },
    getQuery() {
      // console.log('query loading', this.loading)
      if (this.loading) {
        return
      }
      // console.log('get query', this.search)
      this.loading = true;
      let filter   = {perPage: this.perPage, page: this.page};
      if (this.search) {
        filter.search = this.search
      }
      fetch('/public/item/filter', {method: 'POST', body: JSON.stringify({filter: filter})})
          .then(response => response.json()).then(data => {
        this.loading = false;
        this.items   = data.result
        this.page    = data.page;
        this.total   = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
        this.noResult = !(Array.isArray(data.result) && data.result.length)
      }).catch(() => {
        this.loading = false;
      })
    }
  },
  watch  : {
    search(val) {
      // console.log('searching', val)
      this.noResult = false
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = undefined;
      }
      this.timer = setTimeout(() => {
        // console.log('call query', this.getQuery)
        this.getQuery()
      }, 800);
    },
    open(val) {
      if (val) {
        if (this.items.length === 0) {
          this.getQuery()
        }
        setTimeout(() => {
          this.$refs.query.focus()
        }, 100)
        window.$('.frame-modal-wrapper-bg').addClass('is-visible');
        window.$('.search-modal-wrapper').addClass('opened');
        window.$('body').addClass('no-scroll');
      } else {
        window.$('.frame-modal-wrapper-bg').removeClass('is-visible');
        window.$('.search-modal-wrapper').removeClass('opened');
        window.$('body').removeClass('no-scroll');
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/variable";

.frame-modal-wrapper {
  &.search-modal-wrapper {
    h3, .h3 {
      margin-top: 5px;
    }

    .close-cart {
      z-index: 3;
      padding: 0;
      margin: 0;
      font-size: 30px;
      position: absolute;
      opacity: 1;
      right: 20px;
      top: 27px;
      left: auto;

      img {
        width: 24px;
      }
    }

    .price-element {
      &.price-old {
        text-decoration: line-through;
        font-size: 12px;
        align-self: flex-end;
        -webkit-align-self: flex-end;
        margin-left: 10px;
        position: relative;
        top: 0px;
      }
    }

    .price-box {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: flex-end;
      -webkit-align-items: flex-end;
    }

    .search-list {
      margin-top: 25px;
      overflow-y: auto;
      height: 450px;

      li {
        margin-bottom: 0;
        border-bottom: 1px solid @border-color;
        position: relative;

        &:last-child {
          border: none;
        }

        .item-holder {
          width: 100%;
        }

        .option-box {
          margin-top: 5px;
        }

        .item-info {
          .flex();
          .center-align-items();
        }


        .name {
          text-decoration: underline;
          font-weight: 600;
          display: -webkit-box;
          width: 95%;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .price-element {
          &:after {
            content: "грн";
            margin-left: 4px;
          }
        }

        .item-info {

          .right-side {
            width: 75%;
          }

          .image {
            width: 65px;
            min-width: 65px;
            height: 65px;
            border-radius: 6px;
            -moz-border-radius: 6px;
            -webkit-border-radius: 6px;
            margin-right: 15px;
            position: relative;
            overflow: hidden;
            background-color: @light-gray;
          }
        }

        a {
          .flex();
          .center-align-items();
          width: 100%;
          height: 80px;
          padding: 0px 15px;
          position: relative;
          border-radius: 9px;

          &:hover {
            background-color: #f9f9f9;
          }
        }
      }
    }

    .search-input {
      position: relative;

      img {
        width: 30px;
        position: absolute;
        z-index: 3;
        left: 5px;
        top: 11px;
      }

      .form-control {
        &.custom-input {
          padding-left: 40px;
        }
      }
    }
  }
}
@media (min-width: 768px){
  .frame-modal-wrapper {
    &.search-modal-wrapper {
      transform: none;
      width: 100%;
      //background: rgba(0, 0, 0, 0.5);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      justify-content: center;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      top: 0;

      .frame-modal-header {
        text-align: left;
      }

      .modal-wrapper-holder {
        height: 650px;
        width: 500px;
        display: block;
        border-radius: 20px;
      }

      &.opened {
        transform: translatey(0%) translatex(0);
      }

      .search-list {
        height: 375px;

        li {
          border: none;
          margin: 0;


          &:last-child {
            border: none;
          }
        }
      }


      .frame-modal-body {
        padding: 15px;
      }


    }
  }
}
@media (max-width: 767px){
  .frame-modal-wrapper.search-modal-wrapper .search-input img {

    top: 6px;
  }
  .frame-modal-wrapper.search-modal-wrapper .search-list li a:hover {
    background-color: transparent;
  }

  .frame-modal-wrapper.search-modal-wrapper {
    bottom: 52px;
  }

  .frame-modal-wrapper.search-modal-wrapper .search-list {
    padding: 15px;
    margin-top: 0;
  }

  .frame-modal-wrapper.search-modal-wrapper .search-list li a {
    padding: 0;
  }

  .frame-modal-wrapper.search-modal-wrapper .close-cart {
    right: 17px;
    top: 20px;
    left: auto;
  }

  .frame-modal-wrapper.search-modal-wrapper h3, .frame-modal-wrapper.search-modal-wrapper .h3 {
    margin-top: 0;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  .frame-modal-wrapper.search-modal-wrapper .search-input {
    position: relative;
    margin-top: 20px;
    padding: 0px 15px;
  }

  .frame-modal-wrapper.search-modal-wrapper .search-input img {

    left: 25px;
  }
}
</style>