<template>
  <div class="item-holder">
    <div class="product-label label-new" v-if="isHot">
      <span class="label-text">New</span>
    </div>
    <div class="product-label label-sale" v-if="item.price_old">
      <div class="tile_label tile_sale">-{{ getDiscount }}%</div>
    </div>
    <router-link :to="{name: 'Item', params:{ category: categoryURL, id: item.id}}" class="image bg-cover"
                 @click.prevent.stop="" :style="{'background-image': $filters.backgroundImage(item.preview)}">
      <div class="value-info" v-if="weight">
        <span class="name"> {{ mlText }}:</span>
        <span class="value">{{ weight }}</span>
      </div>
    </router-link>
    <div class="item-content">
      <div class="product-name">
        <router-link :to="{name: 'Item', params:{ category: categoryURL, id: item.id}}">
          {{ $dynamic(item, item.title, 'title') }}
        </router-link>

      </div>
    </div>
    <div class="options">
      <div class="price-box">
        <div class="price-element" v-if="item.price"> {{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</div>
        <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }} {{ $store.getters.getSettings.symbol }}</div>
      </div>
      <div class="right-options">
        <div class="particular mobile-hidden">
          <ul>
            <li v-for="(i, index) in ingredient" :key="index">
              <img v-if="i.image" :src="i.image" alt="">
            </li>
          </ul>
        </div>

        <a href="javascript:void(0)" @click="buy" v-if="oClick" class="default-btn btn-icon upsale-btn" :class="{'added-upsale': inBusked}"> <!--add class .added-upsale-->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="default-upsale-icon">
            <path d="M12 17V7" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M7 12L17 12" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="added-upsale-icon">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0253 6.64139C15.2693 6.88546 15.2693 7.28119 15.0253 7.52527L9.19193 13.3586C8.94785 13.6027 8.55213 13.6027 8.30805 13.3586L4.97471 10.0253C4.73064 9.78119 4.73064 9.38546 4.97471 9.14139C5.21879 8.89731 5.61452 8.89731 5.8586 9.14139L8.74999 12.0328L14.1414 6.64139C14.3855 6.39731 14.7812 6.39731 15.0253 6.64139Z" fill="white"/>
          </svg>
        </a>

        <router-link v-else :to="{name: 'Item', params:{ category: categoryURL, id: item.id}}" class="default-btn btn-icon upsale-btn"> <!--add class .added-upsale-->
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="default-upsale-icon">
            <path d="M12 17V7" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M7 12L17 12" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="added-upsale-icon">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0253 6.64139C15.2693 6.88546 15.2693 7.28119 15.0253 7.52527L9.19193 13.3586C8.94785 13.6027 8.55213 13.6027 8.30805 13.3586L4.97471 10.0253C4.73064 9.78119 4.73064 9.38546 4.97471 9.14139C5.21879 8.89731 5.61452 8.89731 5.8586 9.14139L8.74999 12.0328L14.1414 6.64139C14.3855 6.39731 14.7812 6.39731 15.0253 6.64139Z" fill="white"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="particular desktop-hidden">
      <ul>
        <li v-for="(i, index) in ingredient" :key="index">
          <img v-if="i.image" :src="i.image" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name : 'UpsaleItem',
  props: ['item'],
  data() {
    return {
      selectedMod: 0,
      order      : {
        count         : 1
      }
    }
  },
  methods:{
    buy() {
      let idx = -1;
      idx     = this.busked.findIndex(i => i.id === this.item.id);
      if (idx > -1) {
        // this.busked[idx].count += this.order.count;
        if (this.oClick) {
          this.order.count = this.busked[idx].count + 1;
          this.$store.commit(
              'replaceBuskedIndex',
              {index: idx, value: JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))}
          );
          this.order.count = 1;
        }else {
          this.$store.commit(
              'replaceBuskedIndex',
              {index: idx, value: JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))}
          );
        }
      } else {
        // console.log(JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item, {sizeSelect: this.sizeSelected}))));
        let item = JSON.parse(JSON.stringify(Object.assign({}, this.order, this.item)))
        this.$store.commit('addToBasked', item);
        this.$AddToCart(item)
      }
      // window.$('body').addClass('no-scroll');
      // window.$('#quick_cart').addClass('opened');
      // window.$('.frame-modal-wrapper-bg').addClass('is-visible');
    },
  },
  computed: {
    inBusked() {
      return this.busked.find(i => i.id === this.item.id)
    },
    ml() {
      return this.weight ? this.weight.includes('мл') || this.weight.includes('ml') : false
    },
    mlText() {
      return this.ml ? this.$static('fluid', 'Об’єм') : this.$static('weight', 'Вага')
    },
    busked() {
      return this.$store.getters.getBuskedSelected
    },
    categories() {
      return this.$store.getters.getCategories
    },
    oClick() {
      return this.item?.advanced?.oneClick
    },
    getDiscount() {
      return this.item.price_old ? (1 - Math.max(this.item.price / this.item.price_old, 0)) * 100 | 0 : ''
    },
    ingredient() {
      return this.item?.advanced?.options?.ingredient ? this.item?.advanced?.options?.ingredient.map(id => (
          this.ingridients?.list.find(x => x.id === id)
      )).filter(i => !!i) : []
    },
    ingridients() {
      return this.$store.getters.getOptions?.ingredient ? this.$store.getters.getOptions.ingredient : {}
    },
    weight() {
      return this.item?.advanced?.modList?.length ? this.item.advanced.modList[this.selectedMod].gram : ''
    },
    isHot() {
      return this.item?.advanced?.hot
    },
    categoryURL() {
      let category = this.categories.find(x => x.id == this.item.category);
      return category ? category.info.url : ''
    }
  }
}
</script>