<template>
  <div class="custom-option-group" v-if="info && fullList.length && !set.disabled" :aria-label="$updateLang()">
    <div class="custom-label">{{ $dynamic(set, set.option, 'option') }}</div>
    <input type="checkbox" class="show-more" :id="'col'+option_key" v-model="open" style="display: none">
    <div class="custom-option-list">
      <div class="custom-option-checkbox check-image-full" v-for="(item, key) in fullList" :key="key">
        <input :id="'opt'+option_key+key" type="checkbox" :value="item" @change="event => onSelect(event, item)"
               :checked="isSelected(item)" :disabled="isSelectedDisable(item)">
        <label :for="'opt'+option_key+key">
          <span class="pick" v-if="item.image" :style="{'background-image': $filters.backgroundImage(item.image)}"></span>
          <span class="pick" v-else-if="item.color" :style="getColor(item)"></span>
          <span class="text">
          <span class="custom-option-name" v-if="item.name">{{ $dynamic(item, item.name, 'name') }}</span>
          <span class="custom-option-price">
            <span class="price" v-if="item.price"> {{ $filters.money(item.price) }} {{ $store.getters.getSettings.symbol }}</span>
            <span class="price price-old"
                  v-if="item.price_old"> {{ $filters.money(item.price_old) }} {{ $store.getters.getSettings.symbol }}</span>
          </span>
            <span class="value-weight">20 г</span>
        </span>
        </label>
      </div>
    </div>
    <label :for="'col'+option_key" v-if="fullList.length > 4 && !open" class="show-more-label">
      <span class="text">{{ $static('item.show-all', 'Показати всі') }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name : 'ItemOption',
  props: ['option', 'option_key', 'settings'],
  emits: ['select'],
  data() {
    return {
      open    : false,
      last    : undefined,
      selected: []
    }
  },
  mounted() {
    if (this.set.info) {
      if (this.set.info.req && this.fullList.length && !this.set.info.off) {
        this.onSelect(true, this.fullList[0])
        // console.log('options is require', this.fullList[0], this.set)
      }
    }
  },
  methods : {
    isSelected(item) {
      return this.selected.findIndex(i => item.id === i.id) > -1
    },
    isSelectedDisable(item) {
      return this.isSelected(item) && this.disabled
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    onSelect(event, item) {
      // if (this.option.req && item === this.last){
      //   return
      // }
      let status = event
      if (event.target) {
        status = event.target.checked
      }
      if (status === false && this.set?.info?.req && this.selected.length -1 < 1){
        event.stopPropagation()
        event.preventDefault()
        event.target.checked = !status
        return false
      }
      if (this.set.info && this.set.info.single) {
        if (this.last) {
          this.$emit('select', {key: this.option_key, item: this.last, status: false})
        }
        let x = this.selected.findIndex(i => this.last.id === i.id)
        if (x > -1) {
          this.selected.splice(x, 1)
        }
      }
      this.last  = item

      if (status) {
        this.selected.push(item)
      } else {
        let x = this.selected.findIndex(i => item.id === i.id)
        if (x > -1) {
          this.selected.splice(x, 1)
        }
      }
      this.$emit('select', {key: this.option_key, item: item, status: status})
    }
  },
  computed: {
    disabled() {
      return this.option.single ? this.option.req : this.option.req && this.fullList.length === 1
    },
    set() {
      if (this.settings && Array.isArray(this.settings)) {
        let found = this.settings.findIndex(x => x.option_id === this.option_key)
        return found > -1 ? this.settings[found] : {disabled: true}
      }
      return {}
    },
    options_list() {
      return this.$store.getters.getOptions
    },
    info() {
      return this.option_key && this.options_list ? this.options_list[this.option_key] : undefined
    },
    fullList() {
      return this.info && this.option ? this.option.map(id => this.info.list.find(x => x.id == id)).filter(i => i) : []
    }
  }
}
</script>
<style>
.custom-option-list:before {
  z-index    : 1;
  content    : "";
  display    : none;
  width      : 100%;
  left       : 0;
  position   : absolute;
  bottom     : 0;
  height     : 35px;
  background : linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  background : -webkit-linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}

.custom-option-list {
  max-height : 107px;
  overflow   : hidden;
  position   : relative;
}

input[type='checkbox'].show-more:checked + div:before {
  display : none;
}

input[type='checkbox'].show-more:checked + div {
  max-height : inherit;
}

@media (min-width : 768px) {
  .custom-option-list {
    max-height : 121px;
  }
}

@media (min-width : 1200px) {
  .custom-option-list {
    max-height : 121px;
  }
}
</style>